import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import BlogSingle from '../components/BlogSingle'
import Footer from '../components/Footer'
import Header from '../components/Header'
import SingleBlog from '../components/blog/SingleBlog'
import { ThreeCircles } from 'react-loader-spinner'

const Blog = () => {

    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true; // Track whether the component is mounted

        const apiUrl = `${process.env.REACT_APP_API_URL}/api/blog/blogByChildCategory`;

        const fetchData = async () => {
            try {
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ childCat: 'Web/Blogs/News' }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                if (isMounted) { // Only update state if the component is still mounted
                    setBlog(data);
                    setLoading(false);
                }
            } catch (error) {
                if (isMounted) { // Only update state if the component is still mounted
                    setError(error);
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted = false; // Cleanup: set isMounted to false when the component unmounts
        };
    }, []);

    // if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
    return (
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="format-detection" content="telephone=no" />
                <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
                <link rel="canonical" href="https://splendid.productions/Blog" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />

                <title>Tech Blog - Odidor </title>
                <meta name="description" content="Odidor  blogs about code, Agile, web development, startups, Ruby on Rails, Node.js, React Native, mobile, remote work & more." />
                <meta name="keywords" content="code, Agile, web development, startups, Ruby on Rails, Node.js, React Native, mobile, remote work & more." />
                <meta property="og:title" content="Tech Blog - Odidor " />
                <meta property="og:description"
                    content="Odidor  blogs about code, Agile, web development, startups, Ruby on Rails, Node.js, React Native, mobile, remote work & more." />
                <meta property="og:type" content="Website" />
                <meta property="og:url" content="https://splendid.productions/Blog" />
                <meta property="og:image" content="https://splendid.productions/assets/img/services/AppDev.jpg" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />

                <meta name="twitter:title" content="Tech Blog - Odidor " />
                <meta name="twitter:description"
                    content="Odidor  blogs about code, Agile, web development, startups, Ruby on Rails, Node.js, React Native, mobile, remote work & more." />
                <meta name="twitter:image" content="https://splendid.productions/assets/img/services/AppDev.jpg" />
                <meta name="twitter:card" content="Website" />

                <meta property="og:site_name" content="Odidor " />
                <meta property="og:title" content="Tech Blog - Odidor " />
                <meta property="og:type" content="Website" />
                <meta property="og:description"
                    content="Odidor  blogs about code, Agile, web development, startups, Ruby on Rails, Node.js, React Native, mobile, remote work & more." />
                <meta property="og:image" content="https://splendid.productions/assets/img/services/AppDev.jpg" />
                <meta property="og:url" content="https://splendid.productions/Blog" />
            </Helmet>
            <main>
                <Header />
                {(loading) ? (
                    <div className='loader-centered '>
                        <ThreeCircles
                            visible={true}
                            height="100"
                            width="100"
                            color="#5f3afc"
                            ariaLabel="three-circles-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                ) : <SingleBlog b={blog} />}

                <Footer />
            </main>
        </>
    )
}

export default Blog