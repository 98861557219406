import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Helmet } from "react-helmet";

const Contentmarketing = () => {
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <link
          rel="canonical"
          href="https://odidor.co/services/digital-marketing/content-marketing"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />

        <title>Content Marketing | Odidor</title>
        <meta
          name="description"
          content="Odidor provides comprehensive content marketing services to elevate your brand's online presence. Our team creates valuable, engaging content tailored to your audience, driving organic traffic and enhancing brand trust across digital channels."
        />
        <meta
          name="keywords"
          content="Content Marketing, Content Strategy, Brand Engagement, Organic Traffic, Digital Marketing Surrey, Odidor Content Services, Audience Engagement, SEO Content, Social Media Content, Online Presence"
        />

        <meta property="og:title" content="PPC Advertising | Odidor" />
        <meta
          property="og:description"
          content="Odidor provides comprehensive content marketing services to elevate your brand's online presence. Our team creates valuable, engaging content tailored to your audience, driving organic traffic and enhancing brand trust across digital channels."
        />
        <meta property="og:type" content="Website" />
        <meta
          property="og:url"
          content="https://odidor.co/services/digital-marketing/content-marketing"
        />
        <meta
          property="og:image"
          content="https://odidor.co/assets/img/services/digitalmarketing/content.gif"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="720" />

        <meta name="twitter:title" content="Content Marketing | Odidor" />
        <meta
          name="twitter:description"
          content="Odidor provides comprehensive content marketing services to elevate your brand's online presence. Our team creates valuable, engaging content tailored to your audience, driving organic traffic and enhancing brand trust across digital channels."
        />
        <meta
          name="twitter:image"
          content="https://odidor.co/assets/img/services/digitalmarketing/content.gif"
        />
        <meta name="twitter:card" content="Website" />

        <meta property="og:site_name" content="Odidor " />
        <meta property="og:title" content="Content Marketing | Odidor" />
        <meta property="og:type" content="Website" />
        <meta
          property="og:description"
          content="Odidor provides comprehensive content marketing services to elevate your brand's online presence. Our team creates valuable, engaging content tailored to your audience, driving organic traffic and enhancing brand trust across digital channels."
        />
        <meta
          property="og:image"
          content="https://odidor.co/assets/img/services/digitalmarketing/content.gif"
        />
        <meta
          property="og:url"
          content="https://odidor.co/services/digital-marketing/content-marketing"
        />
      </Helmet>
      <main>
        <Header />
        <div className="bg-shape">
          <img
            src="/../assets/img/shape/shape-1.png"
            alt="Content Marketing Services"
          />
        </div>

        <section className="page__title-area pt-50">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__title-content mb-50">
                  <h1 className="page__title">Content Marketing Services</h1>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/services">Services</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/services/digital-marketing">
                          Digital Marketing Solutions
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Content Marketing
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="product__area pb-50">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12">
                <div className="product__wrapper">
                  <div className="postbox__details mb-30">
                    <p className="drop-cap">
                      Content Marketing is essential for engaging your audience,
                      building brand trust, and boosting conversions. At
                      Odidor, we create compelling, high-quality content that
                      resonates with your target market and encourages
                      meaningful interactions with your brand. Our strategy
                      combines creativity with data-driven insights to ensure
                      your content drives results across multiple channels.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 product__details-thumb w-img mb-30">
                <img
                  src="/../assets/img/services/digitalmarketing/content.gif"
                  alt="Content Strategy"
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 postbox__details">
                <p>
                  Our Content Marketing services include strategic planning,
                  content creation, distribution, and performance analysis. We
                  leverage a mix of blog articles, videos, infographics, and
                  social media posts tailored to reach and engage your audience.
                  Our approach ensures content is not only informative but also
                  optimized for search engines to increase visibility.
                  <br />
                  <br />
                  We personalize content to fit your brand’s voice and style,
                  creating a consistent narrative that supports your business
                  objectives. Whether you’re looking to drive awareness or
                  conversions, our content strategy focuses on delivering value
                  to your audience, encouraging loyalty and long-term
                  relationships.
                  <br />
                  <br />
                  From storytelling to data-backed insights, we integrate
                  various elements of content marketing to help your brand
                  connect with customers on a deeper level. Our distribution
                  strategy spans multiple channels, targeting the platforms
                  where your audience is most active.
                  <br />
                  <br />
                  To ensure the success of your content campaigns, we provide
                  in-depth performance tracking, including metrics on
                  engagement, reach, and conversions. This enables us to
                  continually optimize and adapt our strategies for maximum
                  impact.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="services__area pb-110">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="section__title-wrapper mb-50 text-center">
                  <h2 className="section__title">
                    Our Content Marketing Expertise
                  </h2>
                  <p>
                    Strategic content solutions designed to amplify your brand’s
                    message and drive customer engagement.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 d-flex">
                <div
                  className="services__item white-bg mb-30 wow fadeinup animated"
                  data-wow-delay=".9s"
                >
                  <div className="services__icon mb-45">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-4">
                        <span className="blue-bg-4">
                          <i className="fas fa-pencil-alt fa-4x" />
                        </span>
                      </div>
                      <div className="col-8">
                        <h3 className="services__title">
                          <a href="/services/content-marketing/strategy">
                            Content Strategy
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="services__content">
                    <ul>
                      <li>
                        &#8226; Developing customized content plans aligned with
                        your goals.
                      </li>
                      <li>
                        &#8226; Understanding your audience for tailored
                        messaging.
                      </li>
                      <li>
                        &#8226; Strategic content scheduling and distribution.
                      </li>
                    </ul>
                    <a href="/contact" className="link-btn">
                      <i className="arrow_right"></i>Learn More
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 d-flex">
                <div
                  className="services__item white-bg mb-30 wow fadeinup animated"
                  data-wow-delay="1.1s"
                >
                  <div className="services__icon mb-45">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-4">
                        <span className="blue-bg-4">
                          <i className="fas fa-share-alt fa-4x" />
                        </span>
                      </div>
                      <div className="col-8">
                        <h3 className="services__title">
                          <a href="/services/content-marketing/distribution">
                            Content Distribution
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="services__content">
                    <ul>
                      <li>
                        &#8226; Maximizing content reach across digital
                        platforms.
                      </li>
                      <li>
                        &#8226; Targeting your audience on their preferred
                        channels.
                      </li>
                    </ul>
                    <a href="/contact" className="link-btn">
                      <i className="arrow_right"></i>Learn More
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 d-flex">
                <div
                  className="services__item white-bg mb-30 wow fadeinup animated"
                  data-wow-delay="1.3s"
                >
                  <div className="services__icon mb-45">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-4">
                        <span className="blue-bg-4">
                          <i className="fas fa-chart-line fa-4x" />
                        </span>
                      </div>
                      <div className="col-8">
                        <h3 className="services__title">
                          <a href="/services/content-marketing/performance">
                            Performance Analytics
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="services__content">
                    <ul>
                      <li>
                        &#8226; Tracking engagement metrics and conversion
                        rates.
                      </li>
                      <li>&#8226; Regular performance reports and insights.</li>
                      <li>
                        &#8226; Ongoing optimization for continual improvement.
                      </li>
                    </ul>
                    <a href="/contact" className="link-btn">
                      <i className="arrow_right"></i>Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
};

export default Contentmarketing;
