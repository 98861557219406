import React from 'react'
import { Link } from 'react-router-dom';

const SingleBlog = ({ b }) => {

    return (
        <>
            <section class="postbox__area pb-120">
                <div class="container">
                    <div class="row">
                        {b && b.map((blog) => {
                            let slug = blog.slug.split('/')
                            slug = slug[3]
                            return (
                                <div class="col-xxl-6 col-xl-6 col-lg-6 ">
                                    <div class="postbox__wrapper border border-purple">
                                        <article class="postbox__item format-image fix mb-50 wow fadeInUp" data-wow-delay=".2s">
                                            <div class="postbox__thumb">
                                                <a href={`/news-and-updates/details/${slug}`} class="w-img">
                                                    {blog.file &&
                                                        <img src={`${blog.file}`} alt="" />
                                                    }
                                                </a>
                                            </div>
                                            <div class="postbox__content">
                                                <div class="postbox__meta d-flex mb-10">
                                                    <div class="postbox__date">
                                                        {/* <span><i class="fal fa-clock"></i> {blog.updatedAt}</span> */}
                                                    </div>
                                                </div>
                                                <h3 class="postbox__title mb-15"><a href={`/news-and-updates/details/${slug}`}>{blog.title}</a></h3>
                                            </div>
                                        </article>
                                    </div>
                                </div>

                            )
                        })}

                    </div>
                </div>
            </section >

        </>
    )
}

export default SingleBlog