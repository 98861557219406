import React from 'react'

const ServiceSMCard = () => {
    return (
        <div class="product__social m-social grey-bg-2">
            <h4 class="product__social-title">Follow us</h4>
            <ul>
                <li><a href="https://www.facebook.com/SplendidProduction" class="fb"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="https://www.instagram.com/odidor.co/" class="tw" ><i class="fab fa-instagram"></i></a></li>
                <li><a href="https://www.youtube.com/channel/UC_rBAnECubVMspuuA6n8npg" class="tw"><i class="fab fa-youtube"></i></a></li>
            </ul>
        </div>
    )
}

export default ServiceSMCard