import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { Link } from 'react-router-dom';

const Applications = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="format-detection" content="telephone=no" />
                <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
                <link rel="canonical" href="https://odidor.co/SoftwareDevelopment" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />

                <title>Odidor Applications & Softwares</title>
                <meta name="description" content="With cutting-edge technology, intuitive design, and unparalleled support, Odidor empowers businesses and individuals to achieve more every day. Experience seamless integration, robust security features, and innovative solutions tailored to meet your unique needs." />
                <meta name="keywords" content="POS Software development, Customized HRMS developments,Inventory software solutions and development, software development agency" />
                <meta property="og:title" content="Software Development Agency | Software Development Firm" />
                <meta property="og:description"
                    content="With cutting-edge technology, intuitive design, and unparalleled support, Odidor empowers businesses and individuals to achieve more every day. Experience seamless integration, robust security features, and innovative solutions tailored to meet your unique needs." />
                <meta property="og:type" content="Website" />
                <meta property="og:url" content="https://odidor.co/SoftwareDevelopment" />
                <meta property="og:image" content="https://odidor.co/assets/img/services/SoftDev.jpg" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />

                <meta name="twitter:title" content="Software Development Agency | Software Development Firm" />
                <meta name="twitter:description"
                    content="With cutting-edge technology, intuitive design, and unparalleled support, Odidor empowers businesses and individuals to achieve more every day. Experience seamless integration, robust security features, and innovative solutions tailored to meet your unique needs." />
                <meta name="twitter:image" content="https://odidor.co/assets/img/services/SoftDev.jpg" />
                <meta name="twitter:card" content="Website" />

                <meta property="og:site_name" content="Odidor " />
                <meta property="og:title" content="Software Development Agency | Software Development Firm" />
                <meta property="og:type" content="Website" />
                <meta property="og:description"
                    content="With cutting-edge technology, intuitive design, and unparalleled support, Odidor empowers businesses and individuals to achieve more every day. Experience seamless integration, robust security features, and innovative solutions tailored to meet your unique needs." />
                <meta property="og:image" content="https://odidor.co/assets/img/services/SoftDev.jpg" />
                <meta property="og:url" content="https://odidor.co/SoftwareDevelopment" />
            </Helmet>
            <main>
                <Header />

                <div class="bg-shape">
                    <img src="../assets/img/shape/shape-1.png" alt="Odidor  app development" />
                </div>

                <section class="page__title-area  pt-50">
                    <div class="container">
                        <div class="row">
                            <div class="col-xxl-12">
                                <div class="page__title-content mb-50">
                                    <h1 class="section__title wow fadeInUp">Apps powered by <span >Odidor</span></h1>
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                                            <li class="breadcrumb-item active" aria-current="page">App Development </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="product__area po-rel-z1 pb-115 grey-bg">
                    <div className="container">

                        <div className="row">
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 ">
                                <div className="product__item white-bg mb-30 wow fadeInUp" data-wow-delay=".3s">
                                    <div className="product__thumb">
                                        <div className="product__thumb-inner fix w-img">
                                            <a href="/apps">
                                                <img src="assets/img/applications/hippo.png" alt="" />
                                            </a>
                                        </div>
                                        <div className="product__thumb-btn transition-3">
                                            <a href="https://hippo.odidor.app/" target="_blank" className="m-btn m-btn-7">
                                                Preview App
                                            </a>
                                        </div>
                                    </div>
                                    <div className="product__content">
                                        <div className="product__meta mb-10 d-flex justify-content-between align-items-center">
                                            <div className="product__tag">
                                                <a href="https://hippo.odidor.app/" target="_blank" className='text-primary'>
                                                    <i class="fab fa-react"></i> &ensp;Web app</a>
                                            </div>
                                        </div>
                                        <h3 className="product__title">
                                            <a href="/apps">Hippo - Image Compressor </a>
                                        </h3>
                                        <p className="product__author">Hippo can Optimizes your WebP, PNG and JPEG images by 50-80% while preserving full transparency!</p>
                                        <br />
                                    </div>
                                </div>
                            </div>

                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                                <div className="product__item white-bg mb-30 wow fadeInUp" data-wow-delay=".3s">
                                    <div className="product__thumb">
                                        <div className="product__thumb-inner fix w-img">
                                            <a href="/apps">
                                                <img src="assets/img/applications/do-it-app.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="product__thumb-btn transition-3">
                                            <a href="https://play.google.com/store/apps/details?id=splendidproductions.todolist.todolist" target="_blank" className="m-btn m-btn-7" title='Do it Android app by Odidor'>
                                                Preview App
                                            </a>
                                        </div>
                                    </div>
                                    <div className="product__content">
                                        <div className="product__meta mb-10 d-flex justify-content-between align-items-center">
                                            <div className="product__tag">
                                                <a href="https://play.google.com/store/apps/details?id=splendidproductions.todolist.todolist" target="_blank" className='text-success' title='Do it Android app by Odidor'><i class="fab fa-android" />
                                                    &ensp;   Android App
                                                </a>
                                            </div>
                                        </div>
                                        <h3 className="product__title">
                                            <a href="/apps">Do It - Productivity App </a>
                                        </h3>
                                        <p className="product__author">There are plenty of downsides to a pen and paper — you can’t easily reorganize multiple tasks or set notifications for your task list to help you remember deadlines.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                                <div className="product__item white-bg mb-30 wow fadeInUp" data-wow-delay=".3s">
                                    <div className="product__thumb">
                                        <div className="product__thumb-inner fix w-img">
                                            <a href="/apps">
                                                <img src="assets/img/applications/blizard.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="product__thumb-btn transition-3">
                                            <a href="https://play.google.com/store/apps/details?id=com.Splendidstudios.Blizard" target="_blank" className="m-btn m-btn-7" title='Blizard Game by Odidor.co'>
                                                Preview App
                                            </a>
                                        </div>
                                    </div>
                                    <div className="product__content">
                                        <div className="product__meta mb-10 d-flex justify-content-between align-items-center">
                                            <div className="product__tag">
                                                <a href="https://play.google.com/store/apps/details?id=com.Splendidstudios.Blizard" target="_blank" className='' title='Blizard Game by Odidor.co'>
                                                    <i class="fas fa-gamepad" />
                                                    &ensp; Mobile  Game
                                                </a>
                                            </div>
                                        </div>
                                        <h3 className="product__title">
                                            <a href="/apps">Bilzard - Mobile Game </a>
                                        </h3>
                                        <p className="product__author">Made with C# script, simple and light weight game. Vector 2D collider, Rigid Body and Animator are the main game features behind the app.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </main >
        </>
    )
}

export default Applications