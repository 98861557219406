import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <footer>
            <div class="footer__area footer-bg">
                <div class="footer__top pt-90 pb-50">
                    <div class="container">
                        <div class="row">
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-4">
                                <div class="footer__widget mb-40 wow fadeInUp" data-wow-delay=".3s">
                                    <div class="footer__widget-head mb-35">
                                        <h4 class="footer__widget-title">Follow our Socials</h4>
                                    </div>
                                    <div class="footer__widget-content">
                                        <div class="footer__social mb-30">
                                            <ul>
                                                <li><a href="https://www.facebook.com/SplendidProduction" class="fb"><i class="fab fa-facebook-f"></i></a></li>
                                                <li><a href="https://www.instagram.com/odidor.co/" class="tw" ><i class="fab fa-instagram"></i></a></li>
                                                <li><a href="https://www.youtube.com/channel/UC_rBAnECubVMspuuA6n8npg" class="tw"><i class="fab fa-youtube"></i></a></li>
                                            </ul>
                                        </div>
                                        <div class="footer__lang">
                                            <span><a href="https://www.downloadhere.app/">Assets Store</a> </span>
                                            <span><a href="https://splendid.odidor.co/">Studios</a></span>
                                            <span><a href="https://projectlisting.ca/">Project Listing</a></span>
                                            <span><a href="https://odidor.app/">ERP Solution</a></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-4">
                                <div class="footer__widget mb-40 wow fadeInUp" data-wow-delay=".5s">
                                    <div class="footer__widget-head">
                                        <h4 class="footer__widget-title">Solutions</h4>
                                    </div>
                                    <div class="footer__widget-content">
                                        <div class="footer__link">
                                            <ul>
                                                <li><a href="/services/app-development">App Development </a></li>
                                                <li><Link to="/services/website-development">Web Development</Link></li>
                                                <li><Link to="/services/software-development">Software Development </Link></li>
                                                <li><Link to="/services/digital-marketing">Digital Marketing</Link></li>
                                                <li><Link to="/services/enterprise-solutions">Enterprise Solutions</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-4">
                                <div class="footer__widget mb-40 wow fadeInUp footer__pl" data-wow-delay=".7s">
                                    <div class="footer__widget-head">
                                        <h4 class="footer__widget-title">Pages</h4>
                                    </div>
                                    <div class="footer__widget-content">
                                        <div class="footer__link">
                                            <ul>
                                                <li><Link to="/">Home </Link></li>
                                                <li><Link to="/services">Services</Link></li>
                                                <li><Link to="/Blog">Blog</Link></li>
                                                <li><Link to="/Contact">Contact</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-4">
                                <div class="footer__widget mb-40 wow fadeInUp" data-wow-delay=".9s">
                                    <div class="footer__widget-head">
                                        <h4 class="footer__widget-title">Locations</h4>
                                    </div>
                                    <div class="footer__widget-content">
                                        <div class="footer__link">
                                            <ul>
                                                <li><Link to="/location/vancouver" title='Web serviese in Vancouver,BC'>Vancouver</Link></li>
                                                <li><Link to="/location/surrey" title='Web serviese in Surrey,BC'>Surrey</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-4">
                                <div class="footer__widget mb-40 wow fadeInUp" data-wow-delay="1.2s">
                                    <div class="footer__widget-head">
                                        <h4 class="footer__widget-title">Subsidiary</h4>
                                    </div>
                                    <div class="footer__widget-content">
                                        <div class="footer__link">
                                            <ul>
                                                <li><a href="https://www.downloadhere.app/">downloadHere</a></li>
                                                <li><a href="https://splendid.odidor.co/">Splendid Studios</a></li>
                                                <li><a href="https://projectlisting.ca/">Project Listing</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer__bottom">
                    <div class="container">
                        <div class="footer__bottom-inner">
                            <div class="row">
                                <div class="col-xxl-6 col-xl-6 col-md-6">
                                    <div class="footer__copyright wow fadeInUp" data-wow-delay=".5s">
                                        <p>Copyright © All Rights Reserved <a href="https://odidor.co/">Odidor </a></p>
                                    </div>
                                </div>
                                <div class="col-xxl-6 col-xl-6 col-md-6">
                                    <div class="footer__bottom-link wow fadeInUp text-md-end" data-wow-delay=".8s">
                                        <ul>
                                            <li><Link to="/PrivacyPolicy">Privacy Policy </Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer