import React from 'react'
import { Link } from 'react-router-dom'

const ResponseSection = () => {
    return (
        <section class="cta__area pt-100 pb-145 ">
            <div class="container">
                <div class="cta__bg cta__inner pt-90 pb-90">
                    <div class="row">
                        <div class="col-xxl-6 offset-xxl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                            <div class="section__title-wrapper text-center mb-45 wow fadeInUp" data-wow-delay=".3s">
                                <h2 class="section__title text-white">Thank you</h2>
                                <p class="text-white opacity-7">We have received your message! <br /> We will get back to you soon 🤓</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xxl-6 offset-xxl-3">
                            <div class="cta__content text-center wow fadeInUp" data-wow-delay=".5s">
                                <Link to="/" class="m-btn m-btn-white-2 cta__btn"> <span></span> Home</Link>
                                <Link to="/services" class="m-btn m-btn-border-4 cta__btn"> <span></span> Services</Link>
                                <p class="text-white opacity-7"><b>BEST</b> TECH BEST <b>FUTURE</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ResponseSection