import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import BlogSingle from '../components/BlogSingle'
import Footer from '../components/Footer'
import Header from '../components/Header'
import SingleBlog from '../components/blog/SingleBlog'
import { Link, useParams } from 'react-router-dom'
import { ThreeCircles } from 'react-loader-spinner'


const BlogDetails = () => {
    const { slug } = useParams()

    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true; // Track whether the component is mounted

        const apiUrl = `${process.env.REACT_APP_API_URL}/api/blog/Web/Blogs/News/${slug}`;

        const fetchData = async () => {
            try {
                const response = await fetch(apiUrl, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                if (isMounted) { // Only update state if the component is still mounted
                    setBlog(data);
                    setLoading(false);
                }
            } catch (error) {
                if (isMounted) { // Only update state if the component is still mounted
                    setError(error);
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted = false; // Cleanup: set isMounted to false when the component unmounts
        };
    }, [slug]);

    // if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="format-detection" content="telephone=no" />
                <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
                <link rel="canonical" href="https://splendid.productions/Blog" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />

                <title>Tech Blog - Odidor </title>
                <meta name="description" content="Odidor  blogs about code, Agile, web development, startups, Ruby on Rails, Node.js, React Native, mobile, remote work & more." />
                <meta name="keywords" content="code, Agile, web development, startups, Ruby on Rails, Node.js, React Native, mobile, remote work & more." />
                <meta property="og:title" content="Tech Blog - Odidor " />
                <meta property="og:description"
                    content="Odidor  blogs about code, Agile, web development, startups, Ruby on Rails, Node.js, React Native, mobile, remote work & more." />
                <meta property="og:type" content="Website" />
                <meta property="og:url" content="https://splendid.productions/Blog" />
                <meta property="og:image" content="https://splendid.productions/assets/img/services/AppDev.jpg" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />

                <meta name="twitter:title" content="Tech Blog - Odidor " />
                <meta name="twitter:description"
                    content="Odidor  blogs about code, Agile, web development, startups, Ruby on Rails, Node.js, React Native, mobile, remote work & more." />
                <meta name="twitter:image" content="https://splendid.productions/assets/img/services/AppDev.jpg" />
                <meta name="twitter:card" content="Website" />

                <meta property="og:site_name" content="Odidor " />
                <meta property="og:title" content="Tech Blog - Odidor " />
                <meta property="og:type" content="Website" />
                <meta property="og:description"
                    content="Odidor  blogs about code, Agile, web development, startups, Ruby on Rails, Node.js, React Native, mobile, remote work & more." />
                <meta property="og:image" content="https://splendid.productions/assets/img/services/AppDev.jpg" />
                <meta property="og:url" content="https://splendid.productions/Blog" />
            </Helmet>
            <main>
                <Header />
                <div className="bg-shape">
                    <img src="/assets/img/shape/shape-1.png" alt="Blogs" />
                </div>

                <section className="page__title-area  pt-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="page__title-content">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                            <li className="breadcrumb-item"><Link to="/blogs">Blogs & Updates</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Details </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {loading ? (
                    <div className='loader-centered '>
                        <ThreeCircles
                            visible={true}
                            height="100"
                            width="100"
                            color="#5f3afc"
                            ariaLabel="three-circles-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>) : (

                    <><section className="page__title-area  pt-10">
                        <div className="container">
                            <div className="row">
                                <div className="col-xxl-12">
                                    <div className="page__title-content mb-50">
                                        <h2 className="page__title">
                                            {blog.title}
                                        </h2>
                                        <div className="postbox__author-2 mt-20">
                                            <ul className="d-flex align-items-center">
                                                <li>
                                                    <div className="postbox__author-thumb-2">
                                                        <img src="/assets/img/logo-O.png" alt="" />
                                                    </div>
                                                </li>
                                                <li>
                                                    <h6>
                                                        <a href="/contact" title='contact odidor team'>Team Odidor</a>
                                                    </h6>
                                                </li>
                                                <li>
                                                    <h6>{blog.updated} </h6>
                                                    <span>Published</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                        <section className="postbox__area pb-120">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xxl-12 col-xl-12 col-lg-12">
                                        <div className="postbox__wrapper">
                                            <div className="postbox__thumb postbox__thumb-2 fix w-img mb-30">
                                                <a href="#">
                                                    <img src={blog.file} alt="" />
                                                </a>
                                            </div>
                                            <div className="postbox__details mb-30">
                                                <div dangerouslySetInnerHTML={{ __html: blog.content }} />

                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                    </>
                )}

                <Footer />
            </main>
        </>
    )
}

export default BlogDetails