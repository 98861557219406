import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ServiceSidebar from "../../components/ServiceSidebar";
import ServiceSMCard from "../../components/ServiceSMCard";
import ServiceSupport from "../../components/ServiceSupport";

const Enterprisesolutions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <link
          rel="canonical"
          href="https://odidor.co/services/Enterprise-solutions"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />

        <title>
          Enterprise Solutions & Digital Transformation
        </title>
        <meta
          name="description"
          content="We specialize in providing tailored Enterprise Solutions, including Website Development, E-commerce Platforms, and Digital Marketing strategies to enhance your business growth. Contact us at 604-363-3150 for more information."
        />
        <meta
          name="keywords"
          content="Enterprise Solutions, Website Development, Digital Transformation, E-commerce Development, Business Growth, SEO Services, Digital Marketing, CMS Development, Backend Solutions"
        />

        <meta
          property="og:title"
          content="Enterprise Solutions & Digital Transformation"
        />
        <meta
          property="og:description"
          content="We specialize in providing tailored Enterprise Solutions, including Website Development, E-commerce Platforms, and Digital Marketing strategies to enhance your business growth. Contact us at 604-363-3150 for more information."
        />
        <meta property="og:type" content="Website" />
        <meta
          property="og:url"
          content="https://odidor.co/services/Enterprise-solutions"
        />
        <meta
          property="og:image"
          content="https://odidor.co/assets/img/services/Erp.svg"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="720" />

        <meta
          name="twitter:title"
          content="Enterprise Solutions & Digital Transformation"
        />
        <meta
          name="twitter:description"
          content="We specialize in providing tailored Enterprise Solutions, including Website Development, E-commerce Platforms, and Digital Marketing strategies to enhance your business growth. Contact us at 604-363-3150 for more information."
        />
        <meta
          name="twitter:image"
          content="https://odidor.co/assets/img/services/Erp.svg"
        />
        <meta name="twitter:card" content="Website" />

        <meta property="og:site_name" content="Odidor " />
        <meta
          property="og:title"
          content="Enterprise Solutions & Digital Transformation"
        />
        <meta property="og:type" content="Website" />
        <meta
          property="og:description"
          content="We specialize in providing tailored Enterprise Solutions, including Website Development, E-commerce Platforms, and Digital Marketing strategies to enhance your business growth. Contact us at 604-363-3150 for more information."
        />
        <meta
          property="og:image"
          content="https://odidor.co/assets/img/services/Erp.svg"
        />
        <meta
          property="og:url"
          content="https://odidor.co/services/Enterprise-solutions"
        />
      </Helmet>
      <main>
        <Header />
        <div className="bg-shape">
          <img
            src="../assets/img/shape/shape-1.png"
            alt="Odidor Enterprise Solutions"
          />
        </div>

        <section className="page__title-area pt-50">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__title-content mb-50">
                  <h1 className="page__title">Enterprise Solutions</h1>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/services">Services</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Enterprise Solutions
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="product__area pb-115">
          <div className="container">
            <div className="row">
              <div className="col-xxl-8 col-xl-8 col-lg-8">
                <div className="product__wrapper">
                  <div className="product__details-thumb w-img mb-30">
                    <img
                      src="../assets/img/services/Erp.svg"
                      alt="Enterprise Solutions"
                    />
                  </div>

                  <div className="postbox__details mb-30">
                    <p className="drop-cap">
                      Odidor delivers customized enterprise solutions that
                      empower businesses to streamline processes, enhance
                      productivity, and drive growth. Our expert team provides
                      comprehensive development, testing, and maintenance for
                      enterprise-grade applications, ensuring they meet the
                      unique demands of businesses locally and globally.
                    </p>
                  </div>

                  <div className="postbox__details mb-30">
                    <h2 className="mb-30">Our Enterprise Solutions</h2>
                    <h3>Custom Enterprise Development</h3>
                    <p>
                      We specialize in developing bespoke solutions tailored to
                      the needs of each enterprise, ensuring alignment with
                      operational goals and scalability. Our experience spans
                      platforms including Microsoft Dynamics 365, Salesforce,
                      and more, with a focus on driving efficiency and
                      automation in core business processes.
                    </p>

                    <div className="product__features mt-10">
                      <h3 className="product__overview-title">
                        Our Expertise:
                      </h3>
                      <div className="col-12">
                        <a
                          href="/services/enterprise-solutions/erp-development"
                          rel="noreferrer"
                          title="ERP Solutions"
                        >
                          <div
                            className="documentation__item mb-30 text-center"
                            style={{
                              background:
                                "linear-gradient(135deg, rgba(2, 24, 60, 0.9), rgba(56, 0, 56, 0.9))",
                              borderRadius: "10px",
                              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
                            }}
                          >
                            <div className="documentation__icon mb-30">
                              <i className="fas fa-network-wired fa-4x text-light"></i>
                            </div>
                            <div className="documentation__content">
                              <h3 className="documentation__title">
                                ERP Development
                              </h3>
                              <p className="text-light">
                                Comprehensive ERP solutions to unify business
                                processes, enhance data accuracy, and streamline
                                operations.
                              </p>
                              <a
                                href="/services/enterprise-solutions/erp-development"
                                rel="noreferrer"
                                className="m-btn m-btn-border text-light mt-20 rounded-pill"
                              >
                                Explore More
                              </a>
                            </div>
                          </div>
                        </a>
                      </div>

                      {/* CRM Solutions */}
                      <div className="col-12">
                        <a 
                        href="/services/enterprise-solutions/crm-development"
                         rel="noreferrer"
                        title="CRM Solutions">
                          <div
                            className="documentation__item mb-30 text-center"
                            style={{
                              background:
                                "linear-gradient(135deg, rgba(3, 41, 22, 0.9), rgba(107, 164, 58, 0.9))",
                              borderRadius: "10px",
                              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
                            }}
                          >
                            <div className="documentation__icon mb-30">
                              <i className="fas fa-users fa-4x text-light"></i>
                            </div>
                            <div className="documentation__content">
                              <h3 className="documentation__title">
                                CRM Solutions
                              </h3>
                              <p className="text-light">
                                Customized CRM systems to improve sales
                                performance and enhance customer retention.
                              </p>
                              <a  href="/services/enterprise-solutions/crm-development"
                               rel="noreferrer"
                               className="m-btn m-btn-border text-light mt-20 rounded-pill">
                                Explore More
                              </a>
                            </div>
                          </div>
                        </a>
                      </div>

                      {/* Microsoft Integration */}
                      <div className="col-12">
                        <a href="/services/enterprise-solutions/microsoft-integration" rel="noreferrer" title="Microsoft Integration">
                          <div
                            className="documentation__item mb-30 text-center"
                            style={{
                              background:
                                "linear-gradient(135deg, rgba(131, 58, 180, 0.9), rgba(253, 29, 29, 0.9))",
                              borderRadius: "10px",
                              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
                            }}
                          >
                            <div className="documentation__icon mb-30">
                              <i className="fas fa-cloud fa-4x text-light"></i>
                            </div>
                            <div className="documentation__content">
                              <h3 className="documentation__title">
                                Microsoft Integration
                              </h3>
                              <p className="text-light">
                                Seamless integration with Microsoft solutions,
                                including Dynamics 365, Azure, and Power
                                Platform.
                              </p>
                              <a href="/services/enterprise-solutions/microsoft-integration" rel="noreferrer" className="m-btn m-btn-border text-light mt-20 rounded-pill">
                                Explore More
                              </a>
                            </div>
                          </div>
                        </a>
                      </div>

                      {/* IoT Solutions */}
                      <div className="col-12">
                        <a href="/services/enterprise-solutions/iot-solutions" rel="noreferrer" title="IoT Solutions">
                        
                          <div
                            className="documentation__item mb-30 text-center"
                            style={{
                              background:
                                "linear-gradient(135deg, rgba(255, 159, 10, 0.9), rgba(241, 90, 36, 0.9))",
                              borderRadius: "10px",
                              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
                            }}
                          >
                            <div className="documentation__icon mb-30">
                              <i className="fas fa-wifi fa-4x text-light"></i>
                            </div>
                            <div className="documentation__content">
                              <h3 className="documentation__title">
                                IoT Solutions
                              </h3>
                              <p className="text-light">
                                Connect devices, enable real-time monitoring,
                                and optimize operational efficiency with IoT
                                solutions.
                              </p>
                              <a href="/services/enterprise-solutions/iot-solutions" rel="noreferrer" className="m-btn m-btn-border text-light mt-20 rounded-pill">
                                Explore More
                              </a>
                            </div>
                          </div>
                        </a>
                      </div>

                      {/* Big Data Analytics */}
                      <div className="col-12">
                        <a href="/services/enterprise-solutions/big-data-analytics" rel="noreferrer" title="Big Data Analytics">
                          <div
                            className="documentation__item mb-30 text-center"
                            style={{
                              background:
                                "linear-gradient(135deg, rgba(252, 92, 125, 0.9), rgba(106, 130, 251, 0.9))",
                              borderRadius: "10px",
                              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
                            }}
                          >
                            <div className="documentation__icon mb-30">
                              <i className="fas fa-database fa-4x text-light"></i>
                            </div>
                            <div className="documentation__content">
                              <h3 className="documentation__title">
                                Big Data Analytics
                              </h3>
                              <p className="text-light">
                                Process large data sets, derive insights, and
                                drive decision-making with our analytics
                                solutions.
                              </p>
                              <a href="/services/enterprise-solutions/big-data-analytics" rel="noreferrer" className="m-btn m-btn-border text-light mt-20 rounded-pill">
                                Explore More
                              </a>
                            </div>
                          </div>
                        </a>
                      </div>

                      {/* Cloud Solutions */}
                      <div className="col-12">
                        <a href="/services/enterprise-solutions/cloud-solutions" rel="noreferrer" title="Cloud Solutions">
                          <div
                            className="documentation__item mb-30 text-center"
                            style={{
                              background:
                                "linear-gradient(135deg, rgba(0, 191, 255, 0.9), rgba(0, 123, 255, 0.9))",
                              borderRadius: "10px",
                              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
                            }}
                          >
                            <div className="documentation__icon mb-30">
                              <i className="fas fa-cloud-upload-alt fa-4x text-light"></i>
                            </div>
                            <div className="documentation__content">
                              <h3 className="documentation__title">
                                Cloud Solutions
                              </h3>
                              <p className="text-light">
                                Secure, scalable cloud solutions for data
                                storage and team collaboration.
                              </p>
                              <a href="/services/enterprise-solutions/cloud-solutions" rel="noreferrer" className="m-btn m-btn-border text-light mt-20 rounded-pill">
                                Explore More
                              </a>
                            </div>
                          </div>
                        </a>
                      </div>

                      {/* Business Intelligence */}
                      <div className="col-12">
                        <a href="/services/enterprise-solutions/business-intelligence" rel="noreferrer" title="Business Intelligence">
                          <div
                            className="documentation__item mb-30 text-center"
                            style={{
                              background:
                                "linear-gradient(135deg, rgba(255, 195, 0, 0.9), rgba(255, 87, 51, 0.9))",
                              borderRadius: "10px",
                              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
                            }}
                          >
                            <div className="documentation__icon mb-30">
                              <i className="fas fa-chart-line fa-4x text-light"></i>
                            </div>
                            <div className="documentation__content">
                              <h3 className="documentation__title">
                                Business Intelligence
                              </h3>
                              <p className="text-light">
                                Data-driven BI solutions to help you visualize
                                performance and identify growth opportunities.
                              </p>
                              <a href="/services/enterprise-solutions/business-intelligence" rel="noreferrer" className="m-btn m-btn-border text-light mt-20 rounded-pill">
                                Explore More
                              </a>
                            </div>
                          </div>
                        </a>
                      </div>

                      {/* Custom Software Development */}
                      <div className="col-12">
                        <a  href="/services/enterprise-solutions/custom-software-development" rel="noreferrer" title="Custom Software Development">
                          <div
                            className="documentation__item mb-30 text-center"
                            style={{
                              background:
                                "linear-gradient(135deg, rgba(102, 51, 153, 0.9), rgba(0, 153, 204, 0.9))",
                              borderRadius: "10px",
                              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
                            }}
                          >
                            <div className="documentation__icon mb-30">
                              <i className="fas fa-code fa-4x text-light"></i>
                            </div>
                            <div className="documentation__content">
                              <h3 className="documentation__title">
                                Custom Software Development
                              </h3>
                              <p className="text-light">
                                Tailored software solutions to meet unique
                                business needs and boost operational efficiency.
                              </p>
                              <a href="/services/enterprise-solutions/custom-software-development" rel="noreferrer" className="m-btn m-btn-border text-light mt-20 rounded-pill">
                                Explore More
                              </a>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="postbox__details mb-30">
                    <h3>Data and Analytics</h3>
                    <p>
                      Our solutions leverage the power of data analytics and
                      business intelligence to empower enterprises with
                      actionable insights. We provide scalable data solutions
                      tailored to drive informed decisions, optimize workflows,
                      and enhance customer experiences.
                    </p>

                    <div className="product__features mt-10">
                      <h3 className="product__overview-title">
                        Key Services in Data & Analytics:
                      </h3>
                      <ol>
                        <li>
                          <b>Big Data Processing</b>: Scalable solutions for
                          data collection, storage, and analysis to support
                          real-time insights and business intelligence.
                        </li>
                        <li>
                          <b>Business Intelligence (BI)</b>: Transform raw data
                          into valuable insights with custom BI solutions
                          tailored to your strategic goals.
                        </li>
                        <li>
                          <b>Data Security</b>: Advanced data security protocols
                          and compliance measures to protect sensitive business
                          information.
                        </li>
                      </ol>
                    </div>
                  </div>

                  <div className="product__details-content">
                    <div className="product__tab mb-40">
                      <ul className="nav nav-tabs" id="proTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="overview-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#overview"
                            type="button"
                            role="tab"
                            aria-controls="overview"
                            aria-selected="true"
                          >
                            Overview
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="support-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#support"
                            type="button"
                            role="tab"
                            aria-controls="support"
                            aria-selected="false"
                          >
                            Support
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div className="product__tab-content">
                      <div className="tab-content" id="proTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="overview"
                          role="tabpanel"
                          aria-labelledby="overview-tab"
                        >
                          <div className="product__overview">
                            <h3 className="product__overview-title">
                              Our Approach & Vision
                            </h3>
                            <p className="drop-cap">
                              At Odidor, we focus on delivering tailored
                              enterprise solutions that align with business
                              objectives and support sustained growth. Our
                              expertise spans various platforms, enabling
                              businesses to adopt and integrate robust
                              technologies into their operations.
                            </p>
                            <ServiceSMCard />
                          </div>
                        </div>
                        <ServiceSupport />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ServiceSidebar />
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
};

export default Enterprisesolutions;
