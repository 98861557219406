import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Helmet } from "react-helmet";

const Socialmedia = () => {
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <link
          rel="canonical"
          href="https://odidor.co/digital-marketing/social-media-marketing"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />

        <title>Social Media Marketing | Odidor</title>
        <meta
          name="description"
          content="Odidor provides expert Social Media Marketing services to enhance your brand's presence on platforms like Facebook, Instagram, and LinkedIn. We specialize in driving engagement, building audiences, and increasing conversions with tailored strategies for businesses."
        />
        <meta
          name="keywords"
          content="Social Media Marketing Vancouver, Facebook Marketing, Instagram Marketing, LinkedIn Marketing, Social Media Ads, Increase online presence, Social media engagement, Social media strategy, Odidor Social Media Marketing, SMM services Surrey, Social media growth"
        />

        <meta property="og:title" content="Social Media Marketing | Odidor" />
        <meta
          property="og:description"
          content="Odidor provides expert Social Media Marketing services to enhance your brand's presence on platforms like Facebook, Instagram, and LinkedIn. We specialize in driving engagement, building audiences, and increasing conversions with tailored strategies for businesses."
        />
        <meta property="og:type" content="Website" />
        <meta
          property="og:url"
          content="https://odidor.co/digital-marketing/social-media-marketing"
        />
        <meta
          property="og:image"
          content="https://odidor.co/assets/img/services/digitalmarketing/Socialmedia.gif"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="720" />

        <meta name="twitter:title" content="Social Media Marketing | Odidor" />
        <meta
          name="twitter:description"
          content="Odidor provides expert Social Media Marketing services to enhance your brand's presence on platforms like Facebook, Instagram, and LinkedIn. We specialize in driving engagement, building audiences, and increasing conversions with tailored strategies for businesses."
        />
        <meta
          name="twitter:image"
          content="https://odidor.co/assets/img/services/digitalmarketing/Socialmedia.gif"
        />
        <meta name="twitter:card" content="Website" />

        <meta property="og:site_name" content="Odidor " />
        <meta
          property="og:title"
          content="Custom CMS Development Serving | Odidor"
        />
        <meta property="og:type" content="Website" />
        <meta
          property="og:description"
          content="Odidor provides expert Social Media Marketing services to enhance your brand's presence on platforms like Facebook, Instagram, and LinkedIn. We specialize in driving engagement, building audiences, and increasing conversions with tailored strategies for businesses."
        />
        <meta
          property="og:image"
          content="https://odidor.co/assets/img/services/digitalmarketing/Socialmedia.gif"
        />
        <meta
          property="og:url"
          content="https://odidor.co/digital-marketing/social-media-marketing"
        />
      </Helmet>

      <main>
        <Header />
        <div className="bg-shape">
          <img
            src="/../assets/img/shape/shape-1.png"
            alt="Social Media Marketing Services"
          />
        </div>

        <section className="page__title-area pt-50">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__title-content mb-50">
                  <h1 className="page__title">
                    Social Media Marketing Services
                  </h1>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/services">Services</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/services/digital-marketing">
                          Digital Marketing Solutions
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Social Media Marketing
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="product__area pb-50">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12">
                <div className="product__wrapper">
                  <div className="postbox__details mb-30">
                    <p className="drop-cap">
                      Social Media Marketing (SMM) is vital for building your
                      brand's presence across platforms. At Odidor, we
                      specialize in crafting strategies that increase
                      engagement, reach, and conversions across major social
                      media networks.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 product__details-thumb w-img mb-30">
                <img
                  src="/../assets/img/services/digitalmarketing/Socialmedia.gif"
                  alt="Social Media Marketing Strategy"
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 postbox__details">
                <p>
                  Our Social Media Marketing services encompass content
                  creation, audience engagement, and advertising campaigns. We
                  tailor strategies based on your target audience and business
                  goals to maximize your social media performance.
                  <br />
                  <br />
                  We also offer paid social advertising, social media audits,
                  and influencer partnerships to help brands thrive in the
                  digital landscape. By leveraging platform-specific features
                  like Instagram Stories, LinkedIn Posts, and Facebook Ads, we
                  ensure your brand reaches the right audience at the right
                  time.
                </p>
                <ul>
                  <li>&#8226; Organic social media growth strategies.</li>
                  <li>
                    &#8226; Paid advertising campaigns to boost visibility.
                  </li>
                  <li>&#8226; Targeted content creation and scheduling.</li>
                  <li>&#8226; Community management and engagement.</li>
                  <li>&#8226; Influencer marketing and collaborations.</li>
                </ul>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12">
                <div className="postbox__details">
                  <h4>Why Choose Odidor for Social Media Marketing?</h4>
                  <p>
                    Our team of social media experts at Odidor excels at
                    identifying trends and utilizing them to build lasting
                    customer relationships. From content ideation to campaign
                    execution, we handle every aspect of social media marketing,
                    ensuring measurable results for your business.
                    <br />
                    <br />
                    Whether your brand is looking to enhance organic reach or
                    run targeted ads across platforms, we offer the expertise
                    needed to help you stand out in a competitive digital
                    landscape.
                  </p>
                  <ul>
                    <li>
                      &#8226; Data-driven strategies for optimal performance.
                    </li>
                    <li>
                      &#8226; Customized social media plans based on industry
                      trends.
                    </li>
                    <li>
                      &#8226; In-depth reporting and analytics to track success.
                    </li>
                    <li>
                      &#8226; A team dedicated to continuous improvement and
                      innovation.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="services__area pb-110">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="section__title-wrapper mb-50 text-center">
                  <h2 className="section__title">Our Social Media Expertise</h2>
                  <p>
                    Strategic social media services designed to increase brand
                    visibility, engagement, and loyalty.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              {/* Existing Technology Cards */}
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 d-flex">
                <div
                  className="services__item white-bg mb-30 wow fadeinup animated"
                  data-wow-delay=".9s"
                >
                  <div className="services__icon mb-45">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-4">
                        <span className="blue-bg-4">
                          <i className="fab fa-facebook-f fa-4x" />
                        </span>
                      </div>
                      <div className="col-8">
                        <h3 className="services__title">
                          <a href="/services/smm/facebook-marketing">
                            Facebook Marketing
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="services__content">
                    <ul>
                      <li>&#8226; Building a targeted audience on Facebook.</li>
                      <li>
                        &#8226; Driving engagement through content and ads.
                      </li>
                      <li>
                        &#8226; Promoting your products or services effectively.
                      </li>
                    </ul>
                    <a href="/contact" className="link-btn">
                      <i className="arrow_right"></i>Learn More
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 d-flex">
                <div
                  className="services__item white-bg mb-30 wow fadeinup animated"
                  data-wow-delay="1.1s"
                >
                  <div className="services__icon mb-45">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-4">
                        <span className="blue-bg-4">
                          <i className="fab fa-instagram fa-4x" />
                        </span>
                      </div>
                      <div className="col-8">
                        <h3 className="services__title">
                          <a href="/services/smm/instagram-marketing">
                            Instagram Marketing
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="services__content">
                    <ul>
                      <li>&#8226; Crafting visually engaging content.</li>
                      <li>&#8226; Utilizing Instagram stories and reels.</li>
                      <li>&#8226; Growing your follower base organically.</li>
                    </ul>
                    <a href="/contact" className="link-btn">
                      <i className="arrow_right"></i>Learn More
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 d-flex">
                <div
                  className="services__item white-bg mb-30 wow fadeinup animated"
                  data-wow-delay="1.3s"
                >
                  <div className="services__icon mb-45">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-4">
                        <span className="blue-bg-4">
                          <i className="fab fa-linkedin-in fa-4x" />
                        </span>
                      </div>
                      <div className="col-8">
                        <h3 className="services__title">
                          <a href="/services/smm/linkedin-marketing">
                            LinkedIn Marketing
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="services__content">
                    <ul>
                      <li>&#8226; Establishing authority in your industry.</li>
                      <li>&#8226; Generating B2B leads and connections.</li>
                      <li>
                        &#8226; Sharing valuable content to boost your
                        reputation.
                      </li>
                    </ul>
                    <a href="/contact" className="link-btn">
                      <i className="arrow_right"></i>Learn More
                    </a>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* Existing Technology Cards */}
                {/* Twitter Marketing Card */}
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 d-flex">
                  <div
                    className="services__item white-bg mb-30 wow fadeinup animated"
                    data-wow-delay="1.5s"
                  >
                    <div className="services__icon mb-45">
                      <div className="row justify-content-center align-items-center">
                        <div className="col-4">
                          <span className="blue-bg-4">
                            <i className="fab fa-twitter fa-4x" />
                          </span>
                        </div>
                        <div className="col-8">
                          <h3 className="services__title">
                            <a href="/services/smm/twitter-marketing">
                              Twitter Marketing
                            </a>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="services__content">
                      <ul>
                        <li>
                          &#8226; Engaging with your audience in real-time.
                        </li>
                        <li>
                          &#8226; Creating viral content with hashtags and
                          trends.
                        </li>
                        <li>
                          &#8226; Building brand awareness and customer loyalty.
                        </li>
                      </ul>
                      <a href="/contact" className="link-btn">
                        <i className="arrow_right"></i>Learn More
                      </a>
                    </div>
                  </div>
                </div>

                {/* YouTube Marketing Card */}
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 d-flex">
                  <div
                    className="services__item white-bg mb-30 wow fadeinup animated"
                    data-wow-delay="1.7s"
                  >
                    <div className="services__icon mb-45">
                      <div className="row justify-content-center align-items-center">
                        <div className="col-4">
                          <span className="blue-bg-4">
                            <i className="fab fa-youtube fa-4x" />
                          </span>
                        </div>
                        <div className="col-8">
                          <h3 className="services__title">
                            <a href="/services/smm/youtube-marketing">
                              YouTube Marketing
                            </a>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="services__content">
                      <ul>
                        <li>
                          &#8226; Creating compelling video content for your
                          audience.
                        </li>
                        <li>
                          &#8226; Optimizing videos for search visibility.
                        </li>
                        <li>
                          &#8226; Increasing engagement with video ads and
                          promotions.
                        </li>
                      </ul>
                      <a href="/contact" className="link-btn">
                        <i className="arrow_right"></i>Learn More
                      </a>
                    </div>
                  </div>
                </div>

                {/* Pinterest Marketing Card */}
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 d-flex">
                  <div
                    className="services__item white-bg mb-30 wow fadeinup animated"
                    data-wow-delay="1.9s"
                  >
                    <div className="services__icon mb-45">
                      <div className="row justify-content-center align-items-center">
                        <div className="col-4">
                          <span className="blue-bg-4">
                            <i className="fab fa-pinterest-p fa-4x" />
                          </span>
                        </div>
                        <div className="col-8">
                          <h3 className="services__title">
                            <a href="/services/smm/pinterest-marketing">
                              Pinterest Marketing
                            </a>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="services__content">
                      <ul>
                        <li>
                          &#8226; Showcasing your products with visually
                          appealing pins.
                        </li>
                        <li>
                          &#8226; Driving traffic to your website through
                          boards.
                        </li>
                        <li>
                          &#8226; Growing your brand's reach and following.
                        </li>
                      </ul>
                      <a href="/contact" className="link-btn">
                        <i className="arrow_right"></i>Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-12">
                <div className="section__title-wrapper mt-50 mb-50">
                  <h4 className="h3">Key Factors for Social Media Success:</h4>
                  <p>
                    Social Media Marketing is a dynamic process. Key elements
                    include consistent content, authentic engagement, and
                    staying current with platform trends.
                  </p>
                  <ul>
                    <li>
                      &#8226; <b>Content Quality:</b> Creating engaging,
                      visually appealing posts that captivate your audience.
                    </li>
                    <li>
                      &#8226; <b>Audience Engagement:</b> Building meaningful
                      relationships through regular interactions.
                    </li>
                    <li>
                      &#8226; <b>Platform Optimization:</b> Leveraging the
                      strengths of each platform to maximize reach.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
};

export default Socialmedia;
