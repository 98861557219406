const FAQAccordion = () => {
    return (
        <>
            <section class="faq__area grey-bg-2 pt-105 pb-125">
                <div class="container">
                    <div class="row">
                        <div class="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                            <div class="section__title-wrapper text-center mb-65">
                                <h2 class="section__title">Frequently Asked Questions</h2>
                                <p>For any other questions or specific inquiries, please contact through <b><a href="/contact" title="contact for a quote" className="text-primary">contact form</a></b> or email us at <b className="text-success">ryan@odidor.co</b> or call us at <b className="">604 363 3150</b>. We're here to help you! 😎</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-3">
                            <div class="faq__tab wow fadeInLeft" data-wow-delay=".3s">
                                <ul class="nav nav-tabs" id="faqTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="ecom-tab" data-bs-toggle="tab" data-bs-target="#ecom" type="button" role="tab" aria-controls="ecom" aria-selected="true">Ecom</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="branding-tab" data-bs-toggle="tab" data-bs-target="#branding" type="button" role="tab" aria-controls="branding" aria-selected="false">Branding</button>
                                    </li>
                                    {/* <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="ui-tab" data-bs-toggle="tab" data-bs-target="#ui" type="button" role="tab" aria-controls="ui" aria-selected="false">
                                            ERP
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="xd-tab" data-bs-toggle="tab" data-bs-target="#xd" type="button" role="tab" aria-controls="xd" aria-selected="false">Web</button>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-9">
                            <div class="faq__tab-content wow fadeInRight" data-wow-delay=".7s">
                                <div class="tab-content" id="faqTabContent">
                                    {/* Ecom Answers */}
                                    <div class="tab-pane fade show active" id="ecom" role="tabpanel" aria-labelledby="ecom-tab">
                                        <div class="accordion" id="ecom-accordion">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        What are the Ecommerce pricing plans?
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#ecom-accordion">
                                                    <div class="accordion-body">
                                                        <p>Odidor offers several pricing plans to suit different business needs, including a Basic plan, a Professional plan, and an Enterprise plan. Each plan comes with a range of features and capabilities. For detailed pricing information, please visit our <b><a href="/contact" title="contact for a quote" className="text-primary">contact page</a></b> or email us at <b className="text-success">ryan@odidor.co</b> or call us at <b className="">604 363 3150</b></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingTwo">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        What ecommerce platforms is Odidor considering?
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#ecom-accordion">
                                                    <div className="accordion-body">
                                                        <p> There are many popular options such as Shopify, Wix Ecommerce, BigCommerce, etc. Each has its strengths and weaknesses. We can help you compare the features and pricing of different platforms to find the best fit for your needs. Besides these platform we can develop custom Ecommerce platforms for Enterprise solutions. To know more about the solution that we provide follow this link: <b><a href="/services/website-development/ecommerce-web-development" title="contact for a quote" className="text-primary">Ecommerce page.</a></b></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingFour">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                        Can Odidor provide custom ecommerce design and development?
                                                    </button>
                                                </h2>
                                                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#ecom-accordion">
                                                    <div className="accordion-body">
                                                        <p>Absolutely. We offer custom ecommerce development tailored to your unique business requirements. Whether you need specific functionalities or a custom design, our team can deliver a solution that aligns with your vision. Our design team can create a custom, responsive, and user-friendly design that reflects your brand identity and enhances the shopping experience for your customers. To know more about the custom Ecommerce solution follow this link: <b><a href="/services/website-development/ecommerce-web-development" title="Custom Ecommerce solutions" className="text-primary">Ecommerce page</a></b> or email us at <b className="text-success">ryan@odidor.co</b> or call us at <b className="">604 363 3150</b></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        Is customer support included with Odidor's ecommerce solutions?
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#ecom-accordion">
                                                    <div className="accordion-body">
                                                        <p>Absolutely. We offer dedicated customer support to assist with setup, maintenance, and troubleshooting. Our team is available via email, phone, and live chat during business hours and 24/7 support for our Enterprise clients.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingFive">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                        Can Odidor help with digital marketing for my ecommerce site?
                                                    </button>
                                                </h2>
                                                <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#ecom-accordion">
                                                    <div className="accordion-body">
                                                        <p>Yes, we offer digital marketing services including SEO, PPC, social media marketing, email marketing, and content marketing to drive traffic and increase sales for your online store. To know more about the Digital marketing solution follow this link: <b><a href="/services/digital-marketing" title="Digital Marketing page" className="text-primary">Digital Marketing page.</a></b></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Branding */}
                                    <div className="tab-pane fade" id="branding" role="tabpanel" aria-labelledby="branding-tab">
                                        <div className="accordion" id="branding-accordion">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingSix">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                        What services does Odidor offer for branding web solutions?
                                                    </button>
                                                </h2>
                                                <div id="collapseSix" className="accordion-collapse collapse show" aria-labelledby="headingSix" data-bs-parent="#branding-accordion">
                                                    <div className="accordion-body">
                                                        <p>
                                                            Branding is crucial as it helps establish a distinct identity for your business, making it easily recognizable and memorable to your audience. A strong brand can build trust, foster customer loyalty, and differentiate you from competitors.<br />
                                                            <b>Odidor offers comprehensive branding web solutions, including:</b >
                                                            <ul>
                                                                <li>&#8226;  Custom website design and development</li>
                                                                <li>&#8226;  Logo design and branding materials</li>
                                                                <li>&#8226;  Color palette and typography selection</li>
                                                                <li>&#8226;  Brand strategy and consulting</li>
                                                                <li>&#8226;  Social media branding</li>
                                                                <li>&#8226;  Content creation and management</li>
                                                            </ul>

                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingSev">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSev" aria-expanded="false" aria-controls="collapseSev">
                                                        Can you help with social media branding as well?
                                                    </button>
                                                </h2>
                                                <div id="collapseSev" className="accordion-collapse collapse" aria-labelledby="headingSev" data-bs-parent="#branding-accordion">
                                                    <div className="accordion-body">
                                                        <p>Yes, we offer social media branding services, including profile design, cover photos, and consistent visual themes across all your social media platforms to ensure a cohesive brand presence online.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingEig">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEig" aria-expanded="false" aria-controls="collapseEig">
                                                        What branding web solutions does Odidor offer?
                                                    </button>
                                                </h2>
                                                <div id="collapseEig" className="accordion-collapse collapse" aria-labelledby="headingEig" data-bs-parent="#branding-accordion">
                                                    <div className="accordion-body">
                                                        <p>Odidor offers a comprehensive range of branding web solutions, including logo design, brand identity development, website design and development, brand strategy, and digital marketing services.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingNine">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                        How does Odidor ensure brand consistency across different web platforms?
                                                    </button>
                                                </h2>
                                                <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#branding-accordion">
                                                    <div className="accordion-body">
                                                        <p>We develop a detailed brand guide that outlines your visual identity, tone of voice, and messaging. This guide is then applied consistently across your website, social media profiles, and other digital touchpoints.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingTen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="true" aria-controls="collapseTen">
                                                        Can Odidor help with rebranding an existing business?
                                                    </button>
                                                </h2>
                                                <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#branding-accordion">
                                                    <div className="accordion-body">
                                                        <p>Yes, we offer comprehensive rebranding services. This includes reassessing your brand strategy, redesigning visual elements, and updating your web presence to reflect your new brand identity.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingTen">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11" aria-expanded="true" aria-controls="collapse11">
                                                        Can Odidor help integrate my brand across different marketing channels?
                                                    </button>
                                                </h2>
                                                <div id="collapse11" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#branding-accordion">
                                                    <div className="accordion-body">
                                                        <p>Yes, we offer omnichannel branding strategies to ensure your brand is consistently represented across your website, social media, email marketing, and other digital channels.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="tab-pane fade" id="ui" role="tabpanel" aria-labelledby="ui-tab">
                                        <div className="accordion" id="ui-accordion">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingEle">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEle" aria-expanded="false" aria-controls="collapseEle">
                                                        Can Odidor help integrate my brand across different marketing channels?
                                                    </button>
                                                </h2>
                                                <div id="collapseEle" className="accordion-collapse collapse show" aria-labelledby="headingEle" data-bs-parent="#ui-accordion">
                                                    <div className="accordion-body">
                                                        <p>Yes, we offer omnichannel branding strategies to ensure your brand is consistently represented across your website, social media, email marketing, and other digital channels.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingTwl">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwl" aria-expanded="false" aria-controls="collapseTwl">
                                                        What are your office hours?
                                                    </button>
                                                </h2>
                                                <div id="collapseTwl" className="accordion-collapse collapse" aria-labelledby="headingTwl" data-bs-parent="#ui-accordion">
                                                    <div className="accordion-body">
                                                        <p>Only a quid cor blimey guvnor jolly good brolly gutted mate cup of tea cheers it grub blatant, some dodgy chav william buggered my good sir owt to do with me the bee's knees bubble and squeak.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingThi">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThi" aria-expanded="false" aria-controls="collapseThi">
                                                        Can I get support for the free version?
                                                    </button>
                                                </h2>
                                                <div id="collapseThi" className="accordion-collapse collapse" aria-labelledby="headingThi" data-bs-parent="#ui-accordion">
                                                    <div className="accordion-body">
                                                        <p>Only a quid cor blimey guvnor jolly good brolly gutted mate cup of tea cheers it grub blatant, some dodgy chav william buggered my good sir owt to do with me the bee's knees bubble and squeak.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingFourth">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourth" aria-expanded="false" aria-controls="collapseFourth">
                                                        Do your products support RTL?
                                                    </button>
                                                </h2>
                                                <div id="collapseFourth" className="accordion-collapse collapse" aria-labelledby="headingFourth" data-bs-parent="#ui-accordion">
                                                    <div className="accordion-body">
                                                        <p>Only a quid cor blimey guvnor jolly good brolly gutted mate cup of tea cheers it grub blatant, some dodgy chav william buggered my good sir owt to do with me the bee's knees bubble and squeak.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingFiveth">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFivth" aria-expanded="true" aria-controls="collapseFivth">
                                                        Which plan should I choose?
                                                    </button>
                                                </h2>
                                                <div id="collapseFivth" className="accordion-collapse collapse" aria-labelledby="headingFiveth" data-bs-parent="#ui-accordion">
                                                    <div className="accordion-body">
                                                        <p>Only a quid cor blimey guvnor jolly good brolly gutted mate cup of tea cheers it grub blatant, some dodgy chav william buggered my good sir owt to do with me the bee's knees bubble and squeak.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="xd" role="tabpanel" aria-labelledby="xd-tab">
                                        <div className="accordion" id="xd-accordion">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingSixth">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixth" aria-expanded="false" aria-controls="collapseSixth">
                                                        Do you offer custom services & development?
                                                    </button>
                                                </h2>
                                                <div id="collapseSixth" className="accordion-collapse collapse show" aria-labelledby="headingSixth" data-bs-parent="#xd-accordion">
                                                    <div className="accordion-body">
                                                        <p>Only a quid cor blimey guvnor jolly good brolly gutted mate cup of tea cheers it grub blatant, some dodgy chav william buggered my good sir owt to do with me the bee's knees bubble and squeak.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingSevth">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSevth" aria-expanded="false" aria-controls="collapseSevth">
                                                        What are your office hours?
                                                    </button>
                                                </h2>
                                                <div id="collapseSevth" className="accordion-collapse collapse" aria-labelledby="headingSevth" data-bs-parent="#xd-accordion">
                                                    <div className="accordion-body">
                                                        <p>Only a quid cor blimey guvnor jolly good brolly gutted mate cup of tea cheers it grub blatant, some dodgy chav william buggered my good sir owt to do with me the bee's knees bubble and squeak.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingEigth">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEigth" aria-expanded="false" aria-controls="collapseEigth">
                                                        Can I get support for the free version?
                                                    </button>
                                                </h2>
                                                <div id="collapseEigth" className="accordion-collapse collapse" aria-labelledby="headingEigth" data-bs-parent="#xd-accordion">
                                                    <div className="accordion-body">
                                                        <p>Only a quid cor blimey guvnor jolly good brolly gutted mate cup of tea cheers it grub blatant, some dodgy chav william buggered my good sir owt to do with me the bee's knees bubble and squeak.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingNieth">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNieth" aria-expanded="false" aria-controls="collapseNieth">
                                                        Do your products support RTL?
                                                    </button>
                                                </h2>
                                                <div id="collapseNieth" className="accordion-collapse collapse" aria-labelledby="headingNieth" data-bs-parent="#xd-accordion">
                                                    <div className="accordion-body">
                                                        <p>Only a quid cor blimey guvnor jolly good brolly gutted mate cup of tea cheers it grub blatant, some dodgy chav william buggered my good sir owt to do with me the bee's knees bubble and squeak.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingTwth">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwth" aria-expanded="true" aria-controls="collapseTwth">
                                                        Which plan should I choose?
                                                    </button>
                                                </h2>
                                                <div id="collapseTwth" className="accordion-collapse collapse" aria-labelledby="headingTwth" data-bs-parent="#xd-accordion">
                                                    <div class="accordion-body">
                                                        <p>Only a quid cor blimey guvnor jolly good brolly gutted mate cup of tea cheers it grub blatant, some dodgy chav william buggered my good sir owt to do with me the bee's knees bubble and squeak.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FAQAccordion