import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ServiceSidebar from "../../components/ServiceSidebar";
import ServiceSMCard from "../../components/ServiceSMCard";
import ServiceSupport from "../../components/ServiceSupport";

const DevDigmrkg = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <link rel="canonical" href="https://odidor.co/DigitalMarketing" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />

        <title>Digital Marketing and SEO Services in Vancouver - Odidor </title>
        <meta
          name="description"
          content="Odidor  provides seo, pay per click, social media, mobile marketing, ORM, content marketing and event marketing services in Vancouver. For more information call us at 604 363 3250"
        />
        <meta
          name="keywords"
          content="SEO, SEO marketing, Web optimization, marketing solutions, SEO and digital marketing, social media marketing, pay per click, mobile marketing"
        />
        <meta
          property="og:title"
          content="Digital Marketing and SEO Services in Vancouver - Odidor "
        />
        <meta
          property="og:description"
          content="Odidor  provides seo, pay per click, social media, mobile marketing, ORM, content marketing and event marketing services in Vancouver. For more information call us at 604 363 3250"
        />
        <meta property="og:type" content="Website" />
        <meta property="og:url" content="https://odidor.co/DigitalMarketing" />
        <meta
          property="og:image"
          content="https://odidor.co/assets/img/services/DigitalMarketing.jpg"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="720" />

        <meta
          name="twitter:title"
          content="Digital Marketing and SEO Services in Vancouver - Odidor "
        />
        <meta
          name="twitter:description"
          content="Odidor  provides seo, pay per click, social media, mobile marketing, ORM, content marketing and event marketing services in Vancouver. For more information call us at 604 363 3250"
        />
        <meta
          name="twitter:image"
          content="https://odidor.co/assets/img/services/DigitalMarketing.jpg"
        />
        <meta name="twitter:card" content="Website" />

        <meta property="og:site_name" content="Odidor " />
        <meta
          property="og:title"
          content="Digital Marketing and SEO Services in Vancouver - Odidor "
        />
        <meta property="og:type" content="Website" />
        <meta
          property="og:description"
          content="Odidor  provides seo, pay per click, social media, mobile marketing, ORM, content marketing and event marketing services in Vancouver. For more information call us at 604 363 3250"
        />
        <meta
          property="og:image"
          content="https://odidor.co/assets/img/services/DigitalMarketing.jpg"
        />
        <meta property="og:url" content="https://odidor.co/DigitalMarketing" />
      </Helmet>
      <main>
        <Header />
        <div class="bg-shape">
          <img
            src="../assets/img/shape/shape-1.png"
            alt="Odidor  app development"
          />
        </div>

        <section class="page__title-area  pt-50">
          <div class="container">
            <div class="row">
              <div class="col-xxl-12">
                <div class="page__title-content mb-50">
                  <h1 class="page__title">Digital Marketing</h1>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li class="breadcrumb-item">
                        <Link to="/services">Services</Link>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        {" "}
                        Digital Marketing{" "}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="product__area pb-115">
          <div class="container">
            <div class="row">
              <div class="col-xxl-8 col-xl-8 col-lg-8">
                <div class="product__wrapper">
                  <div class="product__details-thumb w-img mb-30">
                    <img
                      src="../assets/img/services/DigitalMarketing.jpg"
                      alt="Digital Marketing"
                    />
                  </div>

                  <div class="postbox__details mb-30">
                    <p class="drop-cap">
                      {" "}
                      In order to reach your audience you need to live where
                      they do – and that’s online. Our targeted digital
                      marketing strategy utilizes the right tools and tactics to
                      ensure your business is always where it needs to be.
                    </p>
                    <p>
                      What makes the team at Odidor stand out is our ability to
                      selectively use the right combination of services to
                      custom-build digital marketing solutions perfectly matched
                      to your business’ needs. We work with you to understand
                      and achieve your strategic goals; our focus is not simply
                      driving traffic to your website, but delivering the right
                      traffic.
                    </p>
                  </div>

                  <div class="postbox__details mb-30">
                    <h2 class="mb-30"> Digital Marketing Solutions</h2>
                    <h3>SEO Services</h3>
                    <p>
                      Our SEO services can help expand your reach by ensuring
                      you rank high on popular search engine results. Our SEO
                      services find the fastest and most efficient path to page
                      rankings, and our A.I. systems determines the most
                      important metrics to focus on by reverse-engineering the
                      Google Algorithm for your high-priority keywords.
                    </p>
                    <div class="product__features mt-10">
                      <h3 class="product__overview-title">SEO Strategy</h3>
                      <ol>
                        <li>
                          <p>
                            <b>On-page SEO</b> : This SEO focuses on the content
                            that's actually on site pages, and how to optimize
                            it to boost the website's ranking for specific
                            keywords.
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Off-page SEO</b> : This SEO focuses on links
                            directed to the website from elsewhere on the
                            internet. The number of backlinks a site has from
                            reputable sources helps you build trust with search
                            algorithms.
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Technical SEO</b> : This SEO focuses on a
                            website's backend architecture, like site code.
                            Google cares just as much about technical set-up as
                            it does content, so this position is important for
                            rankings.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div className="product__features mt-10">
                    <h3 className="product__overview-title">
                      Our Digital Marketing Expertise:
                    </h3>
                    {/* SEO Services */}
                    <div className="col-12">
                      <a
                        href="/services/digital-marketing/seo-services"
                        title="SEO Services"
                      >
                        <div className="documentation__item gradient-pink mb-30 text-center">
                          <div className="documentation__icon mb-30">
                            <i class="fas fa-search fa-4x text-light"></i>
                          </div>
                          <div className="documentation__content">
                            <h3 className="documentation__title">
                              <a
                                href="/services/digital-marketing/seo-services"
                                title="SEO Services"
                              >
                                SEO Services
                              </a>
                            </h3>
                            <p className="text-light">
                              Our SEO services enhance your website’s visibility
                              on search engines, driving organic traffic and
                              improving search rankings.
                            </p>
                            <a
                              href="/services/digital-marketing/seo-services"
                              title="SEO Services"
                              className="m-btn m-btn-border text-light mt-20 rounded-pill"
                            >
                              Explore More
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                    {/* Social Media Marketing */}
                    <div className="col-12">
                      <a
                        href="/services/digital-marketing/social-media-marketing"
                        title="Social Media Marketing"
                      >
                        <div className="documentation__item gradient-blue mb-30 text-center">
                          <div className="documentation__icon mb-30">
                            <i class="fas fa-share-alt fa-4x text-light"></i>
                          </div>
                          <div className="documentation__content">
                            <h3 className="documentation__title">
                              <a
                                href="/services/digital-marketing/social-media-marketing"
                                title="Social Media Marketing"
                              >
                                Social Media Marketing
                              </a>
                            </h3>
                            <p className="text-light">
                              Empower your brand with strategic social media
                              marketing that drives engagement and boosts your
                              online presence.
                            </p>
                            <a
                              href="/services/digital-marketing/social-media-marketing"
                              title="Social Media Marketing"
                              className="m-btn m-btn-border text-light mt-20 rounded-pill"
                            >
                              Explore More
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                    {/* PPC Advertising */}
                    <div className="col-12">
                      <a
                        href="/services/digital-marketing/ppc-advertising"
                        title="PPC Advertising"
                      >
                        <div className="documentation__item gradient-orange mb-30 text-center">
                          <div className="documentation__icon mb-30">
                            <i class="fas fa-bullseye fa-4x text-light"></i>
                          </div>
                          <div className="documentation__content">
                            <h3 className="documentation__title">
                              <a
                                href="/services/digital-marketing/ppc-advertising"
                                title="PPC Advertising"
                              >
                                PPC Advertising
                              </a>
                            </h3>
                            <p className="text-light">
                              Maximize your ROI with our tailored PPC
                              advertising strategies that drive targeted traffic
                              and generate leads.
                            </p>
                            <a
                              href="/services/digital-marketing/ppc-advertising"
                              title="PPC Advertising"
                              className="m-btn m-btn-border text-light mt-20 rounded-pill"
                            >
                              Explore More
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                    {/* Content Marketing */}
                    <div className="col-12">
                      <a
                        href="/services/digital-marketing/content-marketing"
                        title="Content Marketing"
                      >
                        <div className="documentation__item gradient-purple mb-30 text-center">
                          <div className="documentation__icon mb-30">
                            <i class="fas fa-file-alt fa-4x text-light"></i>
                          </div>
                          <div className="documentation__content">
                            <h3 className="documentation__title">
                              <a
                                href="/services/digital-marketing/content-marketing"
                                title="Content Marketing"
                              >
                                Content Marketing
                              </a>
                            </h3>
                            <p className="text-light">
                              Engage your audience with compelling content that
                              informs, entertains, and drives customer
                              conversions.
                            </p>
                            <a
                              href="/services/digital-marketing/content-marketing"
                              title="Content Marketing"
                              className="m-btn m-btn-border text-light mt-20 rounded-pill"
                            >
                              Explore More
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div class="postbox__details mb-30">
                    <h3>PPC Services</h3>
                    <p>
                      Advertise smarter, not harder, by targeting consumers who
                      are looking to buy from businesses in your segment.
                    </p>

                    <div class="product__features mt-10">
                      <h3 class="product__overview-title">PPC Solutions</h3>
                      <ol>
                        <li>
                          <p>
                            <b>Optimization</b> : Your landing page is where
                            your audience is going to 'land' when they click on
                            your ad. Landing pages are incredibly crucial when
                            it comes to deciding whether a specific customer is
                            going to buy your software or not. Your landing page
                            must have a clear and distinguished headline and a
                            defined call-to-action (CTA). 90% of visitors who
                            read your headline will also read your
                            call-to-action.
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Target The Right Keywords</b> : The importance of
                            the right keywords cannot be emphasized enough when
                            it comes to drafting a potent ad campaign. 0.16% of
                            the most popular keywords are responsible for 60.67%
                            of all searches. You might do well if you search on
                            the hot keywords in the software industry to draft
                            an ad campaign for your software website.
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Target The Right Audience</b> : Before launching
                            your PPC marketing campaign, research the audience
                            that your PPC ad will be targeting. This research
                            should be based on the demographics of your target
                            audience. One of the things that makes a PPC ad
                            stand out is the fact that you know your target
                            audience. Adverts placed on the Google Display
                            Network reach up to 90% of internet users. Use this
                            to target where you place your PPC ads.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>

                  <div class="postbox__details mb-30">
                    <h3>SMM Services</h3>
                    <p>
                      Stay connected and stay relevant with focused social media
                      marketing strategy aimed at growing your brand
                      recognition. Social media marketing gives businesses the
                      unique ability to reach targeted customers and drive
                      traffic to your website in an organic way – the best
                      marketing is marketing that doesn’t feel like marketing at
                      all.
                    </p>

                    <div class="product__features mt-10">
                      <h3 class="product__overview-title">SMM Solutions</h3>
                      <ol>
                        <li>
                          <p>
                            Generate more leads and sales via social media ads
                          </p>
                        </li>
                        <li>
                          <p>Develop a custom social media ad strategy</p>
                        </li>
                        <li>
                          <p>Manage social media advertising campaigns</p>
                        </li>
                      </ol>
                    </div>
                  </div>

                  <div class="postbox__quote-2 mb-50">
                    <blockquote>
                      <h4>
                        “It’s hard to find things that won’t sell online.”
                      </h4>
                      <span>- Jeff Bezos</span>
                    </blockquote>
                  </div>

                  <div class="product__details-content">
                    <div class="product__tab mb-40">
                      <ul class="nav nav-tabs" id="proTab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link active"
                            id="overview-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#overview"
                            type="button"
                            role="tab"
                            aria-controls="overview"
                            aria-selected="true"
                          >
                            Overview
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link"
                            id="support-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#support"
                            type="button"
                            role="tab"
                            aria-controls="support"
                            aria-selected="false"
                          >
                            Support
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div class="product__tab-content">
                      <div class="tab-content" id="proTabContent">
                        <div
                          class="tab-pane fade show active"
                          id="overview"
                          role="tabpanel"
                          aria-labelledby="overview-tab"
                        >
                          <div class="product__overview">
                            <h3 class="product__overview-title">
                              Our Vision & Specs
                            </h3>
                            <p class="drop-cap">
                              Our tailored, end-to-end digital marketing
                              solutions allow us to reach your target audience
                              across multiple platforms, search engines, and
                              social media sites. Let us show you how we can
                              improve your rankings, boost performance, and
                              maximize ROI.
                            </p>
                            <ServiceSMCard />
                          </div>
                        </div>
                        <ServiceSupport />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ServiceSidebar />
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
};

export default DevDigmrkg;
