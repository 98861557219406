import React, { useRef, useState } from 'react'
import emailjs from 'emailjs-com'


const Newsletter = () => {
    //Email JS
    const form = useRef();

    const [subscribed, setSubscribed] = useState(false);

    const submitHandler = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_49hnncs', 'template_7utqxep', form.current, 'UKLJOJp3C_GQ_cugL')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        setSubscribed(true)
    };
    return (
        <>
            {!subscribed ? (
                <section class="subscribe__area p-relative pt-100 pb-110" style={{ backgroundImage: "url('/assets/img/bg/subscribe-bg.jpg')" }}>
                    <div class="subscribe__icon">
                        <img class="ps" src="assets/img/icon/subscribe/ps.png" alt="" />
                        <img class="wp" src="assets/img/icon/subscribe/wp.png" alt="" />
                        <img class="html" src="assets/img/icon/subscribe/html.png" alt="" />
                        <img class="f" src="assets/img/icon/subscribe/f.png" alt="" />
                        <img class="man" src="assets/img/icon/subscribe/man.png" alt="" />
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-xxl-12">
                                <div class="subscribe__content text-center wow fadeInUp" data-wow-delay=".5s">
                                    <h3 class="subscribe__title">Have a project? <br /> Share with us now</h3>
                                    <p>Let’s start something great together</p>
                                    <div class="subscribe__form wow fadeInUp" data-wow-delay=".7s">
                                        <form ref={form} onSubmit={submitHandler}>
                                            <input type="email" placeholder="email" name="email" />
                                            <button type="submit" class="m-btn m-btn-black"><span></span> Let's Start </button>
                                        </form>
                                        <p>Best Tech, Best Future</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <section class="subscribe__area p-relative pt-100 pb-110" style={{ backgroundImage: "url('/assets/img/bg/subscribe-bg.jpg')" }}>
                    <div class="subscribe__icon">
                        <img class="ps" src="assets/img/icon/subscribe/ps.png" alt="" />
                        <img class="wp" src="assets/img/icon/subscribe/wp.png" alt="" />
                        <img class="html" src="assets/img/icon/subscribe/html.png" alt="" />
                        <img class="f" src="assets/img/icon/subscribe/f.png" alt="" />
                        <img class="man" src="assets/img/icon/subscribe/man.png" alt="" />
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-xxl-12">
                                <div class="subscribe__content text-center wow fadeInUp" data-wow-delay=".5s">
                                    <h3 class="subscribe__title">We have received you email! <br /> We will get back to you soon😎</h3>
                                    <div class="subscribe__form wow fadeInUp" data-wow-delay=".7s">
                                        <p>Best Tech, Best Future</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
            }
        </>

    )
}

export default Newsletter