import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Newsletter from '../components/Newsletter'
import ServiceCard from '../components/ServiceCard'
import FAQAccordion from '../components/FAQAccordion'

const Services = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="format-detection" content="telephone=no" />
                <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
                <link rel="canonical" href="https://odidor.co/Services" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />

                <title>Web, App development & Digital Marketing, SEO</title>
                <meta name="description" content="Odidor  offer web design, development, and digital strategy services. For more information, call us at 604-363-3150. " />
                <meta name="keywords" content="Web development, app development, web designs and custom website  development, E-commerce web development and solutions, SEO and digital marketing solutions, CMS & Backend development" />
                <meta property="og:title" content="Web development & Design services, SEO" />
                <meta property="og:description"
                    content="Odidor  offer web design, development, and digital strategy services. For more information, call us at 604-595-2495." />
                <meta property="og:type" content="Website" />
                <meta property="og:url" content="https://odidor.co/Services" />
                <meta property="og:image" content="https://odidor.co/assets/img/services/Services.jpg" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />

                <meta name="twitter:title" content="Web, App development & Digital Marketing Splendid Production" />
                <meta name="twitter:description"
                    content="Odidor  offer web design, development, and digital strategy services. For more information, call us at 604-595-2495." />
                <meta name="twitter:image" content="https://odidor.co/assets/img/services/Services.jpg" />
                <meta name="twitter:card" content="Website" />

                <meta property="og:site_name" content="Odidor " />
                <meta property="og:title" content="Web, App development & Digital Marketing Splendid Production" />
                <meta property="og:type" content="Website" />
                <meta property="og:description"
                    content="Odidor  offer web design, development, and digital strategy services. For more information, call us at 604-595-2495." />
                <meta property="og:image" content="https://odidor.co/assets/img/services/Services.jpg" />
                <meta property="og:url" content="https://odidor.co/Services" />
            </Helmet>

            <main>
                <Header />
                <div class="bg-shape">
                    <img src="../assets/img/shape/shape-1.png" alt="Odidor  app development" />
                </div>

                <section class="page__title-area  pt-50">
                    <div class="container">
                        <div class="row">
                            <div class="col-xxl-12">
                                <div class="page__title-content mb-50">
                                    <h1 class="page__title">What we offer</h1>
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                                            <li class="breadcrumb-item"><Link to="/services">Services</Link></li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="product__area pb-0">
                    <div class="container">
                        <div class="row">
                            <div class="col-xxl-12 col-xl-12 col-lg-12">
                                <div class="product__wrapper">

                                    <div class="product__details-thumb w-img mb-30">
                                        <img src="../assets/img/services/Services.jpg" alt="Digital Marketing" />
                                    </div>

                                    <div class="postbox__details mb-30">
                                        <p>
                                            What makes the team at Odidor  stand out is our ability to selectively use the right combination of services to custom-build digital marketing solutions perfectly matched to your business’ needs. We work with you to understand and achieve your strategic goals; our focus is not simply driving traffic to your website, but delivering the right traffic.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <ServiceCard />

                <div class="container">
                    <div class="postbox__quote-2 mb-50">
                        <blockquote>
                            <h4>“The first step is to establish that something is possible; then probability will occur.”
                            </h4>
                            <span>- Elon Musk</span>
                        </blockquote>
                    </div>
                </div>

                <FAQAccordion />

                <div class="container mt-50 mb-80">
                    <div class="row">
                        <div className='col-12'>
                            <div className="section__title-wrapper text-center mb-30">
                                <h1 className="section__title">Why Odidor?</h1>
                            </div>
                            <iframe src="https://www.slideshare.net/slideshow/embed_code/key/4UPQd8SwpwjLGL?hostedIn=slideshare&page=upload" width="100%" height="600" frameborder="0" marginwidth="0" marginheight="0" scrolling="no"></iframe>
                        </div>
                    </div>
                </div>

                <Newsletter />


                <Footer />
            </main>
        </>
    )
}

export default Services