import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

import Header from '../components/Header'
import Footer from '../components/Footer'
import ContactForm from '../components/ContactForm'

const Contact = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="format-detection" content="telephone=no" />
                <link rel="canonical" href="https://odidor.co/Contact" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />

                <title>Contact now - Odidor </title>
                <meta name="description" content="Before we start, we would like to better understand your needs. We’ll review your application and schedule a free estimation call." />
                <meta property="og:title" content="Contact now - Odidor " />
                <meta property="og:description"
                    content="Before we start, we would like to better understand your needs. We’ll review your application and schedule a free estimation call." />
                <meta property="og:type" content="Website" />
                <meta property="og:url" content="https://odidor.co/Contact" />
                <meta property="og:image" content="https://odidor.co/assets/img/banner/01.png" />
                <meta property="og:image:width" content="" />
                <meta property="og:image:height" content="" />

                <meta name="twitter:title" content="Contact now - Odidor " />
                <meta name="twitter:description"
                    content="Before we start, we would like to better understand your needs. We’ll review your application and schedule a free estimation call." />
                <meta name="twitter:image" content="https://odidor.co/assets/img/banner/01.png" />
                <meta name="twitter:card" content="Website" />

                <meta property="og:site_name" content="Odidor " />
                <meta property="og:title" content="Contact now - Odidor " />
                <meta property="og:type" content="Website" />
                <meta property="og:description"
                    content="Before we start, we would like to better understand your needs. We’ll review your application and schedule a free estimation call." />
                <meta property="og:image" content="https://odidor.co/assets/img/banner/01.png" />
                <meta property="og:url" content="https://odidor.co/Contact" />
            </Helmet>
            <Header />
            <section class="contact__area pt-105 pb-145">
                <div class="contact__shape">
                    <img class="man-1" src="assets/img/icon/sign/man-1.png" alt="" />
                    <img class="circle" src="assets/img/icon/sign/circle.png" alt="" />
                    <img class="zigzag" src="assets/img/icon/sign/zigzag.png" alt="" />
                    <img class="dot" src="assets/img/icon/sign/dot.png" alt="" />
                    <img class="bg" src="assets/img/icon/sign/sign-up.png" alt="" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12">
                            <div className="page__title-wrapper mb-55">
                                <h2 className="page__title-2">Leave Us a Message.</h2>
                                <p>We assure the right technology to you.</p>
                            </div>
                        </div>
                    </div>
                    <ContactForm />
                </div>
            </section>

            <section class="contact__info pt-20 pb-120">
                <div class="contact__info-shape">
                    <img src="assets/img/icon/contact/contact-bg.png" alt="" />
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-xxl-12">
                            <div class="page__title-wrapper text-center mb-55">
                                <h2 class="page__title-2">We'd love to <br />hear from you</h2>
                                <p>Stay in touch with us</p>
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-evenly">
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                            <div class="contact__item text-center white-bg mb-30 transition-3">
                                <div class="contact__icon mb-30 d-flex justify-content-center align-items-center">
                                    <img src="assets/img/icon/contact/mail.png" alt="" />
                                </div>
                                <div class="contact__content">
                                    <h4 class="contact__content-title">
                                        <a href="mailto:ryan@odidor.co">ryan@odidor.co</a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                            <div class="contact__item text-center white-bg mb-30 transition-3">
                                <div class="contact__icon mb-30 d-flex justify-content-center align-items-center">
                                    <img src="assets/img/icon/contact/phone.png" alt="" />
                                </div>
                                <div class="contact__content">
                                    <h4 class="contact__content-title"><a href="tel:+(604)-363-3150">+(604) 363 3150</a> </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Contact