const Partners = () => {
    return (
        <div className="container pb-60">
            <div className="row text-center">
                <div className="col">
                    <h2>Our Partners</h2>
                    <p >At Odidor we are proud to collaborate with a diverse group of industry leaders and innovators who share our commitment to excellence, creativity, and technological advancement. Our partners are integral to our success, enabling us to deliver top-tier solutions to our clients across various domains. We are proud to partner with these amazing organizations.</p>
                </div>
            </div>
            <div className="row text-center">
                <div className="col-md-3 col-6 mb-4">
                    <a href='https://www.goodfirms.co/' target="_blank" title='Odidor partnering with Odidor'>
                        <img src="./assets/img/partners/good-firms.png"
                            alt="Odidor partnering with Good Firms"
                            className="partner-logo img-fluid" />
                    </a>
                </div>
                {/* <div className="col-md-3 col-6 mb-4">
                <img src="partner-logo2.png" alt="Partner 2" className="partner-logo img-fluid" />
            </div> */}
            </div>
        </div>
    )
}

export default Partners