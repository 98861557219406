import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'
import ResponseSection from '../components/ResponseSection'

const ThankYou = () => {
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0);

        window.setTimeout(function () {
            navigate('/')
        }, 10000);
    }, []);

    return (
        <>
            <main>
                <Header />
                <ResponseSection />
            </main>
            <Footer />
        </>
    )
}

export default ThankYou