import React from 'react'
import ServiceSMCard from './ServiceSMCard'

const ServiceSupport = () => {
    return (
        <div class="tab-pane fade" id="support" role="tabpanel" aria-labelledby="support-tab">
            <div class="product__support">
                <div class="latest-comments mb-20">
                    <ul>
                        <li>
                            <div class="comments-box grey-bg-2">
                                <div class="comments-info d-flex">
                                    <div class="comments-avatar mr-15">
                                        <img src="https://avatars.dicebear.com/v2/male/32771ea106fdcc017cc67e821bbadaf3.svg" alt="" />
                                    </div>
                                    <div class="avatar-name">
                                        <h5>Ryan</h5>
                                        <span class="post-meta">Customer Service</span>
                                    </div>
                                </div>
                                <div class="comments-text ml-65">
                                    <p>Need help with our project? Hi! 👋🏼 there, email me to <a href="mailto:ryan@odidor.co">ryan@odidor.co</a></p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <ServiceSMCard />
        </div>
    )
}

export default ServiceSupport