import React from "react";
import { Link } from "react-router-dom";

const ServiceCard = () => {
  return (
    <section className="category__area pt-105 pb-60">
      <div className="container">
        <div className="row">
          <div className="col-xxl-12">
            <div className="section__title-wrapper text-center mb-60">
              <h1 className="section__title">
                Browse <br /> Our Services
              </h1>
              <p>
                Odidor: Your one-stop solution for comprehensive web and software services. We specialize in digital marketing, custom web development, mobile app creation, and software development. Our expert team delivers tailored, innovative solutions to boost your online presence and drive business growth. From SEO strategies to cutting-edge mobile apps, Odidor transforms your digital vision into reality. Partner with us for powerful, results-driven web solutions that set you apart in the digital landscape.
              </p>
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-center flex-wrap">
          <div className="col-lg-4 col-md-6 col-sm-6 mb-4">
            <div
              className="category__item transition-3 text-center white-bg mb-30 wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div className="category__icon mb-25">
                <Link to="/services/app-development">
                  <img src="assets/img/icon/category/cat-1.png" alt="App Development" />
                </Link>
              </div>
              <div className="category__content">
                <h3 className="category__title">
                  <Link to="/services/app-development">App Development</Link>
                </h3>
                <Link to="/services/app-development" className="link-btn">
                  <i className="far fa-long-arrow-right"></i> Learn More
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 mb-4">
            <div
              className="category__item transition-3 text-center white-bg mb-30 wow fadeInUp"
              data-wow-delay=".5s"
            >
              <div className="category__icon mb-25">
                <Link to="/services/website-development">
                  <img src="assets/img/icon/category/cat-2.png" alt="Web Development" />
                </Link>
              </div>
              <div className="category__content">
                <h3 className="category__title">
                  <Link to="/services/website-development">Web Development</Link>
                </h3>
                <Link to="/services/website-development" className="link-btn">
                  <i className="far fa-long-arrow-right"></i> Learn More
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 mb-4">
            <div
              className="category__item transition-3 text-center white-bg mb-30 wow fadeInUp"
              data-wow-delay=".7s"
            >
              <div className="category__icon mb-25">
                <Link to="/services/enterprise-solutions">
                  <img src="assets/img/icon/category/cat-1.png" alt="Enterprise Solutions" />
                </Link>
              </div>
              <div className="category__content">
                <h3 className="category__title">
                  <Link to="/services/enterprise-solutions">Enterprise Solutions</Link>
                </h3>
                <Link to="/services/enterprise-solutions" className="link-btn">
                  <i className="far fa-long-arrow-right"></i> Learn More
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 mb-4">
            <div
              className="category__item transition-3 text-center white-bg mb-30 wow fadeInUp"
              data-wow-delay=".9s"
            >
              <div className="category__icon mb-25">
                <Link to="/services/software-development">
                  <img src="assets/img/icon/category/cat-3.png" alt="Software Development" />
                </Link>
              </div>
              <div className="category__content">
                <h3 className="category__title">
                  <Link to="/services/software-development">Software Dev</Link>
                </h3>
                <Link to="/services/software-development" className="link-btn">
                  <i className="far fa-long-arrow-right"></i> Learn More
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 mb-4">
            <div
              className="category__item transition-3 text-center white-bg mb-30 wow fadeInUp"
              data-wow-delay="1.1s"
            >
              <div className="category__icon mb-25">
                <Link to="/services/digital-marketing">
                  <img src="assets/img/icon/category/cat-4.png" alt="Digital Marketing" />
                </Link>
              </div>
              <div className="category__content">
                <h3 className="category__title">
                  <Link to="/services/digital-marketing">Digital Marketing</Link>
                </h3>
                <Link to="/services/digital-marketing" className="link-btn">
                  <i className="far fa-long-arrow-right"></i> Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceCard;
