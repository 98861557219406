import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'
import ServiceSidebar from '../components/ServiceSidebar'
import ServiceSMCard from '../components/ServiceSMCard'
import ServiceSupport from '../components/ServiceSupport'

const DevSoft = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="format-detection" content="telephone=no" />
                <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
                <link rel="canonical" href="https://odidor.co/SoftwareDevelopment" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />

                <title>Software Development Agency | Software Development Firm</title>
                <meta name="description" content="Work with one of the top software development agencies. Our team can help scope, design and build your next product. Get a free estimate for your project" />
                <meta name="keywords" content="POS Software development, Customized HRMS developments,Inventory software solutions and development, software development agency" />
                <meta property="og:title" content="Software Development Agency | Software Development Firm" />
                <meta property="og:description"
                    content="Work with one of the top software development agencies. Our team can help scope, design and build your next product. Get a free estimate for your project" />
                <meta property="og:type" content="Website" />
                <meta property="og:url" content="https://odidor.co/SoftwareDevelopment" />
                <meta property="og:image" content="https://odidor.co/assets/img/services/SoftDev.jpg" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />

                <meta name="twitter:title" content="Software Development Agency | Software Development Firm" />
                <meta name="twitter:description"
                    content="Work with one of the top software development agencies. Our team can help scope, design and build your next product. Get a free estimate for your project" />
                <meta name="twitter:image" content="https://odidor.co/assets/img/services/SoftDev.jpg" />
                <meta name="twitter:card" content="Website" />

                <meta property="og:site_name" content="Odidor " />
                <meta property="og:title" content="Software Development Agency | Software Development Firm" />
                <meta property="og:type" content="Website" />
                <meta property="og:description"
                    content="Work with one of the top software development agencies. Our team can help scope, design and build your next product. Get a free estimate for your project" />
                <meta property="og:image" content="https://odidor.co/assets/img/services/SoftDev.jpg" />
                <meta property="og:url" content="https://odidor.co/SoftwareDevelopment" />
            </Helmet>
            <main>
                <Header />
                <div class="bg-shape">
                    <img src="../assets/img/shape/shape-1.png" alt="Odidor  app development" />
                </div>

                <section class="page__title-area  pt-50">
                    <div class="container">
                        <div class="row">
                            <div class="col-xxl-12">
                                <div class="page__title-content mb-50">
                                    <h1 class="page__title">Web Development Solutions</h1>
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                                            <li class="breadcrumb-item"><Link to="/services">Services</Link></li>
                                            <li class="breadcrumb-item active" aria-current="page">Web Development </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="product__area pb-115">
                    <div class="container">
                        <div class="row">
                            <div class="col-xxl-8 col-xl-8 col-lg-8">
                                <div class="product__wrapper">

                                    <div class="product__details-thumb w-img mb-30">
                                        <img src="../assets/img/services/SoftDev.jpg" alt="Software Development" />
                                    </div>

                                    <div class="postbox__details mb-30">
                                        <p class="drop-cap"> We provide top custom software development services for any business that needs a digital solution for its daily problems. Our flexible, reliable frameworks for development are customized to the customer’s needs and requirements. From building a brand to automating business with advanced technologies, our industry experts take care of everything.
                                        </p>
                                    </div>

                                    <div class="postbox__details mb-30">
                                        <h2 class="mb-30"> Custom Software Solutions</h2>
                                        <h3>POS System</h3>
                                        <p>Manage your sales more efficiently by automating every process, from billing to inventory management, no matter what your business size is. We provide custom POS software solutions across all domains to help businesses make the most out of every sale.
                                        </p>
                                        <div class="product__features mt-10">
                                            <h3 class="product__overview-title">Our POS System Expertise:</h3>
                                            <ol>
                                                <li>
                                                    <p><b>Self-service Kiosk POS Systems</b> :  Quick-service restaurants and casual food businesses with self-checkout</p>
                                                </li>
                                                <li>
                                                    <p><b>Open Source POS Systems</b> : Businesses with abundant technical resources to create and maintain a custom system</p>
                                                </li>
                                                <li>
                                                    <p><b>Touch-Screen POS Systems</b> : Businesses wanting to speed up the ordering and checkout process</p>
                                                </li>
                                            </ol>
                                        </div>
                                    </div>

                                    <div class="postbox__details mb-30">
                                        <h3>Customized HRMS</h3>
                                        <p>
                                            We build mobile and web-based custom HR software solutions for all businesses across the globe. Irrespective of the industry, our HR solutions automate HR operations like recruitment, measuring performance, timesheets, activity tracking, and reporting.
                                        </p>

                                        <div class="product__features mt-10">
                                            <h3 class="product__overview-title">HRMS Solutions</h3>
                                            <ol>
                                                <li>
                                                    <p><b>Performance Evaluation</b> : HRMS will monitor each employee’s success and help HR individuals to track each individual’s appraisal.</p>
                                                </li>
                                                <li>
                                                    <p><b>Employee Management</b> : Employee management in HRMS allows workers to deliver their best daily work, helping a company to expand.</p>
                                                </li>
                                                <li>
                                                    <p><b>Greater Control</b> : The recurring and monitoring process can be more effective when reports and data are stored in a single place.</p>
                                                </li>
                                            </ol>
                                        </div>
                                    </div>

                                    <div class="postbox__quote-2 mb-50">
                                        <blockquote>
                                            <h4>“Software is a great combination between artistry and engineering.”
                                            </h4>
                                            <span>- Bill Gates</span>
                                        </blockquote>
                                    </div>

                                    <div class="product__details-content">
                                        <div class="product__tab mb-40">
                                            <ul class="nav nav-tabs" id="proTab" role="tablist">
                                                <li class="nav-item" role="presentation">
                                                    <button class="nav-link active" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true">Overview</button>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <button class="nav-link" id="support-tab" data-bs-toggle="tab" data-bs-target="#support" type="button" role="tab" aria-controls="support" aria-selected="false">Support</button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="product__tab-content">
                                            <div class="tab-content" id="proTabContent">
                                                <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                                                    <div class="product__overview">
                                                        <h3 class="product__overview-title">Our Vision & Specs</h3>
                                                        <p class="drop-cap">We are dedicated to developing custom software that are 100% tailored to your needs. Here, all we build is for you and in collaboration with you, so think of us as your team’s expansion, and usually, our teams work alongside yours</p>
                                                        <ServiceSMCard />
                                                    </div>
                                                </div>
                                                <ServiceSupport />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ServiceSidebar />
                        </div>
                    </div>
                </section>
                <Footer />
            </main>
        </>
    )
}

export default DevSoft