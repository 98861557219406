import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet"
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import DevApp from "./pages/devApp/DevApp";
import DevSoft from "./pages/DevSoft";
import DevWeb from "./pages/devWeb/DevWeb";
import Home from './pages/Home'
import Login from "./pages/Login";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Services from "./pages/Services";
import ThankYou from "./pages/ThankYou";
import ThanksGiving from "./pages/promotions/greetings/ThanksGiving";
import Applications from "./pages/Applications";
import Surrey from "./pages/locations/Surrey";
import Vancouver from "./pages/locations/Vancouver";
import Ecommerce from "./pages/devWeb/Ecommerce";
import WebApp from "./pages/devWeb/WebApp";
import BlogDetails from "./pages/BlogDetails";
import Cms from "./pages/devWeb/Customcms";
import Opensource from "./pages/devWeb/Opensource";
import CustomApp from "./pages/devApp/Customapp";
import MobileApp from "./pages/devApp/Mobileapp";
import AndroidApp from "./pages/devApp/Androidapp";
import IosApp from "./pages/devApp/IosApp";
import DevDigmrkg from "./pages/devdIgital/DevDigmrkg";
import Seoservices from "./pages/devdIgital/Seoservices";
import Socialmedia from "./pages/devdIgital/Socialmedia";
import Ppc from "./pages/devdIgital/Ppc";
import Contentmarketing from "./pages/devdIgital/Contentmark";
import Enterprisesolutions from "./pages/EnterpriseSolutions/Enterprisesolutions";
import Erpdev from "./pages/EnterpriseSolutions/Erpdev";
import Crmdev from "./pages/EnterpriseSolutions/Crmdev";
import Microsoftdev from "./pages/EnterpriseSolutions/Microsoftdev";
import Iotdev from "./pages/EnterpriseSolutions/Iotdev";
import Bigdataanalytics from "./pages/EnterpriseSolutions/Bigdataanalytics";
import Cloudsolutions from "./pages/EnterpriseSolutions/Cloudsolutions";
import Businessintelligence from "./pages/EnterpriseSolutions/Businessintelligence";
import Customsoftwaredev from "./pages/EnterpriseSolutions/Customsoftwaredev";


function App() {
  return (
    <Router>
      <Helmet>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="format-detection" content="telephone=no" />
        <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
        <link rel="canonical" href="https://odidor.co/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />

        <title>Web, App development & Digital Marketing Splendid Production</title>
        <meta name="description" content="Web Design Vancouver - Splendid Production is a Vancouver web & app development company offering unique and affordable web and app design, development and eCommerce website design solutions in Vancouver. " />
        <meta name="keywords" content="Web development, app development, web designs and custom website  development, E-commerce web development and solutions, SEO and digital marketing solutions, CMS & Backend development" />
        <meta property="og:title" content="Web development & Design services" />
        <meta property="og:description"
          content="Web Design Vancouver - Splendid Production is a Vancouver web & app development company offering unique and affordable web and app design, development and eCommerce website design solutions in Vancouver." />
        <meta property="og:type" content="Website" />
        <meta property="og:url" content="https://odidor.co/" />
        <meta property="og:image" content="https://odidor.co/assets/img/banner/01.png" />
        <meta property="og:image:width" content="" />
        <meta property="og:image:height" content="" />

        <meta name="twitter:title" content="Web, App development & Digital Marketing Splendid Production" />
        <meta name="twitter:description"
          content="Web Design Vancouver - Splendid Production is a Vancouver web & app development company offering unique and affordable web and app design, development and eCommerce website design solutions in Vancouver." />
        <meta name="twitter:image" content="https://odidor.co/assets/img/banner/01.png" />
        <meta name="twitter:card" content="Website" />

        <meta property="og:site_name" content="Odidor " />
        <meta property="og:title" content="Web, App development & Digital Marketing Splendid Production" />
        <meta property="og:type" content="Website" />
        <meta property="og:description"
          content="Web Design Vancouver - Splendid Production is a Vancouver web & app development company offering unique and affordable web and app design, development and eCommerce website design solutions in Vancouver." />
        <meta property="og:image" content="https://odidor.co/assets/img/banner/01.png" />
        <meta property="og:url" content="https://odidor.co/" />
      </Helmet>
      <Routes>
        {/* Main Pages */}
        <Route path="/*" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/apps" element={<Applications />} />
        
        {/* Website development */}
        <Route path="/services/website-development" element={<DevWeb />} />
        <Route path="/services/website-development/ecommerce-web-development" element={<Ecommerce />} />
        <Route path="/services/website-development/web-app-development" element={<WebApp />} />
        <Route path="/services/website-development/custom-cms-development" element={<Cms />} />
        <Route path="/services/website-development/open-source-development" element={<Opensource />} />
        {/* app development */}
        <Route path="/services/app-development" element={<DevApp />} />
        <Route path="/services/app-development/custom-app-development" element={<CustomApp />} />
        <Route path="/services/app-development/mobile-app-development" element={<MobileApp />} />
        <Route path="/services/app-development/android-app-development" element={<AndroidApp />} />
        <Route path="/services/app-development/ios-app-development" element={<IosApp />} />
        {/* Digital marketing */}
        <Route path="/services/digital-marketing" element={<DevDigmrkg />} />
        <Route path="/services/digital-marketing/seo-services" element={<Seoservices/>} />
        <Route path="/services/digital-marketing/social-media-marketing" element={<Socialmedia/>} />
        <Route path="/services/digital-marketing/ppc-advertising" element={<Ppc/>} />
        <Route path="/services/digital-marketing/content-marketing" element={<Contentmarketing/>} />
        {/*Enterprisesolutions*/}
        <Route path="/services/enterprise-solutions" element={<Enterprisesolutions/>} />
        <Route path="/services/enterprise-solutions/erp-development" element={<Erpdev/>} />
        <Route path="/services/enterprise-solutions/crm-development" element={<Crmdev/>} />
        <Route path="/services/enterprise-solutions/microsoft-integration" element={<Microsoftdev/>} />
        <Route path="/services/enterprise-solutions/iot-solutions" element={<Iotdev/>} />
        <Route path="/services/enterprise-solutions/big-data-analytics" element={<Bigdataanalytics/>} />
        <Route path="/services/enterprise-solutions/cloud-solutions" element={<Cloudsolutions/>} />
        <Route path="/services/enterprise-solutions/business-intelligence" element={<Businessintelligence/>} />
        <Route path="/services/enterprise-solutions/custom-software-development" element={<Customsoftwaredev/>} />
        
        <Route path="/services/software-development" element={<DevSoft />} />
        <Route path="/news-and-updates" element={<Blog />} />
        <Route path="/news-and-updates/details/:slug" element={<BlogDetails />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Thank-You" element={<ThankYou />} />

        {/* Location Pages */}
        <Route path="/location/surrey" element={<Surrey />} />
        <Route path="/location/vancouver" element={<Vancouver />} />

        {/* Greetings pages */}
        {/* <Route path="/happy-thanksgiving" element={<ThanksGiving />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
