import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Helmet } from "react-helmet";

const AndroidApp = () => {
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <link
          rel="canonical"
          href="https://odidor.co/services/app-development/android-app-development"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />

        <title>Android App Development Serving | Odidor</title>
        <meta
          name="description"
          content="Odidor specializes in developing high-quality Android applications that offer robust performance, scalability, and intuitive user experiences. Our Android app development services are designed to help businesses engage their customers and drive growth through cutting-edge mobile solutions."
        />
        <meta
          name="keywords"
          content="Android app development Vancouver, Custom Android apps, Android application development, Kotlin app development, Java Android apps, Android app agency Surrey, Mobile app development, Android app solutions, Scalable Android applications, Android app developers"
        />

        <meta
          property="og:title"
          content="Android App Development Serving | Odidor"
        />
        <meta
          property="og:description"
          content="Odidor specializes in developing high-quality Android applications that offer robust performance, scalability, and intuitive user experiences. Our Android app development services are designed to help businesses engage their customers and drive growth through cutting-edge mobile solutions."
        />
        <meta property="og:type" content="Website" />
        <meta
          property="og:url"
          content="https://odidor.co/services/app-development/android-app-development"
        />
        <meta
          property="og:image"
          content="https://odidor.co/assets/img/services/devapp/android-development.gif"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="720" />

        <meta
          name="twitter:title"
          content="Android App Development Serving | Odidor"
        />
        <meta
          name="twitter:description"
          content="Odidor specializes in developing high-quality Android applications that offer robust performance, scalability, and intuitive user experiences. Our Android app development services are designed to help businesses engage their customers and drive growth through cutting-edge mobile solutions."
        />
        <meta
          name="twitter:image"
          content="https://odidor.co/assets/img/services/devapp/android-development.gif"
        />
        <meta name="twitter:card" content="Website" />

        <meta property="og:site_name" content="Odidor " />
        <meta
          property="og:title"
          content="Android App Development Serving | Odidor"
        />
        <meta property="og:type" content="Website" />
        <meta
          property="og:description"
          content="Odidor specializes in developing high-quality Android applications that offer robust performance, scalability, and intuitive user experiences. Our Android app development services are designed to help businesses engage their customers and drive growth through cutting-edge mobile solutions."
        />
        <meta
          property="og:image"
          content="https://odidor.co/assets/img/services/devapp/android-development.gif"
        />
        <meta
          property="og:url"
          content="https://odidor.co/services/app-development/android-app-development"
        />
      </Helmet>
      <main>
        <Header />
        <div className="bg-shape">
          <img
            src="/../assets/img/shape/shape-1.png"
            alt="Android App Development"
          />
        </div>

        <section className="page__title-area pt-50">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__title-content mb-50">
                  <h1 className="page__title">Android App Development</h1>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/services">Services</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/services/app-development">
                          App Solutions
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Android App Development
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="product__area pb-50">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12">
                <div className="product__wrapper">
                  <div className="postbox__details mb-30">
                    <p className="drop-cap">
                      Developing high-quality Android applications is key to
                      expanding your business reach. At Odidor, we focus on
                      creating robust, scalable Android apps that provide
                      excellent user experiences while driving engagement and
                      accessibility across Android devices.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 product__details-thumb w-img mb-30">
                <img
                  src="/../assets/img/services/devapp/android-development.gif"
                  alt="Android Development"
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 postbox__details">
                <p>
                  Our Android app development services encompass a wide range of
                  solutions, from native Android apps tailored to your unique
                  business needs, to seamless migration from other platforms.
                  Leveraging technologies like Java and Kotlin, we ensure your
                  app offers smooth performance and a highly customizable user
                  interface that enhances both functionality and user
                  satisfaction.
                  <br />
                  <br />
                  We also ensure seamless integration with Google services,
                  providing extensive features such as in-app payments, location
                  services, and data analytics, helping your business stay ahead
                  of the curve.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="services__area pb-110">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="section__title-wrapper mb-50 text-center">
                  <h2 className="section__title">
                    Our Android Development Expertise
                  </h2>
                  <p>
                    Comprehensive Android development solutions designed to
                    enhance functionality, user experience, and scalability for
                    businesses of all sizes.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              {/* Existing Technology Cards */}
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 d-flex">
                <div
                  className="services__item white-bg mb-30 wow fadeinup animated"
                  data-wow-delay=".9s"
                >
                  <div className="services__icon mb-45">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-4">
                        <span className="blue-bg-4">
                          <i className="fab fa-android fa-4x" />
                        </span>
                      </div>
                      <div className="col-8">
                        <h3 className="services__title">
                          <a
                            href="https://developer.android.com/"
                            target="_blank"
                          >
                            Android
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="services__content">
                    <ul>
                      <li>&#8226; Native Android app development.</li>
                      <li>
                        &#8226; Highly customizable UI for optimal user
                        engagement.
                      </li>
                      <li>
                        &#8226; Integration with Google services and ecosystem.
                      </li>
                    </ul>
                    <a href="/contact" className="link-btn">
                      <i className="arrow_right"></i>Learn More
                    </a>
                  </div>
                </div>
              </div>

              {/* Add Kotlin Technology Card */}
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 d-flex">
                <div
                  className="services__item white-bg mb-30 wow fadeinup animated"
                  data-wow-delay="1.2s"
                >
                  <div className="services__icon mb-45">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-4">
                        <span className="blue-bg-4">
                          <i className="fab fa-korvue fa-4x" />
                        </span>
                      </div>
                      <div className="col-8">
                        <h3 className="services__title">
                          <a href="https://kotlinlang.org/" target="_blank">
                            Kotlin
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="services__content">
                    <ul>
                      <li>&#8226; Robust Android development using Kotlin.</li>
                      <li>
                        &#8226; Modern, concise syntax for efficient app
                        development.
                      </li>
                      <li>
                        &#8226; Enhanced performance with Kotlin’s built-in null
                        safety.
                      </li>
                    </ul>
                    <a href="/contact" className="link-btn">
                      <i className="arrow_right"></i>Learn More
                    </a>
                  </div>
                </div>
              </div>

              {/* Add Java Technology Card */}
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 d-flex">
                <div
                  className="services__item white-bg mb-30 wow fadeinup animated"
                  data-wow-delay="1.5s"
                >
                  <div className="services__icon mb-45">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-4">
                        <span className="blue-bg-4">
                          <i className="fab fa-java fa-4x" />
                        </span>
                      </div>
                      <div className="col-8">
                        <h3 className="services__title">
                          <a href="https://www.java.com/" target="_blank">
                            Java
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="services__content">
                    <ul>
                      <li>&#8226; Proven Android development with Java.</li>
                      <li>
                        &#8226; Reliable and scalable mobile apps built with
                        Java.
                      </li>
                      <li>
                        &#8226; Comprehensive Google Play Services integration.
                      </li>
                    </ul>
                    <a href="/contact" className="link-btn">
                      <i className="arrow_right"></i>Learn More
                    </a>
                  </div>
                </div>
              </div>

              {/* Add Flutter Technology Card */}
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 d-flex">
                <div
                  className="services__item white-bg mb-30 wow fadeinup animated"
                  data-wow-delay="1.8s"
                >
                  <div className="services__icon mb-45">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-4">
                        <span className="blue-bg-4">
                          <i className="fas fa-mobile-alt fa-4x" />{" "}
                        </span>
                      </div>
                      <div className="col-8">
                        <h3 className="services__title">
                          <a href="https://flutter.dev/" target="_blank">
                            Flutter
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="services__content">
                    <ul>
                      <li>&#8226; Cross-platform development with Flutter.</li>
                      <li>
                        &#8226; Smooth, high-performing apps for Android and
                        iOS.
                      </li>
                      <li>
                        &#8226; Flexible UI built using Flutter's widget-based
                        architecture.
                      </li>
                    </ul>
                    <a href="/contact" className="link-btn">
                      <i className="arrow_right"></i>Learn More
                    </a>
                  </div>
                </div>
              </div>

              {/* Add Firebase Technology Card */}
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 d-flex">
                <div
                  className="services__item white-bg mb-30 wow fadeinup animated"
                  data-wow-delay="2.1s"
                >
                  <div className="services__icon mb-45">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-4">
                        <span className="blue-bg-4">
                          <i className="fas fa-cloud fa-4x" />
                        </span>
                      </div>
                      <div className="col-8">
                        <h3 className="services__title">
                          <a
                            href="https://firebase.google.com/"
                            target="_blank"
                          >
                            Firebase
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="services__content">
                    <ul>
                      <li>
                        &#8226; Backend services with Firebase for Android apps.
                      </li>
                      <li>
                        &#8226; Real-time database, authentication, and storage
                        solutions.
                      </li>
                      <li>
                        &#8226; Enhanced scalability for dynamic Android
                        applications.
                      </li>
                    </ul>
                    <a href="/contact" className="link-btn">
                      <i className="arrow_right"></i>Learn More
                    </a>
                  </div>
                </div>
              </div>

              {/* Add Android Jetpack Technology Card */}
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 d-flex">
                <div
                  className="services__item white-bg mb-30 wow fadeinup animated"
                  data-wow-delay="2.4s"
                >
                  <div className="services__icon mb-45">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-4">
                        <span className="blue-bg-4">
                          <i className="fas fa-toolbox fa-4x" />
                        </span>
                      </div>
                      <div className="col-8">
                        <h3 className="services__title">
                          <a
                            href="https://developer.android.com/jetpack"
                            target="_blank"
                          >
                            Android Jetpack
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="services__content">
                    <ul>
                      <li>
                        &#8226; Modular components for efficient Android app
                        development.
                      </li>
                      <li>
                        &#8226; Easy-to-maintain architecture using Jetpack
                        libraries.
                      </li>
                      <li>
                        &#8226; Best practices for smooth and responsive app
                        experiences.
                      </li>
                    </ul>
                    <a href="/contact" className="link-btn">
                      <i className="arrow_right"></i>Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
};

export default AndroidApp;
