import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Helmet } from "react-helmet";

const Seoservices = () => {
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <link
          rel="canonical"
          href="https://odidor.co/services/digital-marketing/seo-services"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />

        <title>SEO Services | Odidor</title>
        <meta
          name="description"
          content="Odidor offers expert SEO services to help businesses improve online visibility, increase search rankings, and drive targeted traffic. Our customized SEO strategies are designed to enhance digital presence, ensuring sustained growth and success in search engines."
        />
        <meta
          name="keywords"
          content="SEO services Vancouver, Search engine optimization, Increase website ranking, Local SEO, Organic traffic growth, SEO agency Surrey, Keyword optimization, Technical SEO, On-page SEO, SEO for businesses, Odidor SEO services"
        />

        <meta property="og:title" content="SEO Services | Odidor" />
        <meta
          property="og:description"
          content="Odidor offers expert SEO services to help businesses improve online visibility, increase search rankings, and drive targeted traffic. Our customized SEO strategies are designed to enhance digital presence, ensuring sustained growth and success in search engines."
        />
        <meta property="og:type" content="Website" />
        <meta
          property="og:url"
          content="https://odidor.co/services/digital-marketing/seo-services"
        />
        <meta
          property="og:image"
          content="https://odidor.co/assets/img/services/digitalmarketing/SEO.gif"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="720" />

        <meta name="twitter:title" content="SEO Services | Odidor" />
        <meta
          name="twitter:description"
          content="Odidor offers expert SEO services to help businesses improve online visibility, increase search rankings, and drive targeted traffic. Our customized SEO strategies are designed to enhance digital presence, ensuring sustained growth and success in search engines."
        />
        <meta
          name="twitter:image"
          content="https://odidor.co/assets/img/services/digitalmarketing/SEO.gif"
        />
        <meta name="twitter:card" content="Website" />

        <meta property="og:site_name" content="Odidor " />
        <meta property="og:title" content="SEO Services | Odidor" />
        <meta property="og:type" content="Website" />
        <meta
          property="og:description"
          content="Odidor offers expert SEO services to help businesses improve online visibility, increase search rankings, and drive targeted traffic. Our customized SEO strategies are designed to enhance digital presence, ensuring sustained growth and success in search engines."
        />
        <meta
          property="og:image"
          content="https://odidor.co/assets/img/services/digitalmarketing/SEO.gif"
        />
        <meta
          property="og:url"
          content="https://odidor.co/services/digital-marketing/seo-services"
        />
      </Helmet>

      <main>
        <Header />
        <div className="bg-shape">
          <img src="/../assets/img/shape/shape-1.png" alt="SEO Services" />
        </div>

        <section className="page__title-area pt-50">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__title-content mb-50">
                  <h1 className="page__title">SEO Services</h1>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/services">Services</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/services/digital-marketing">
                          Digital Marketing Solutions
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        SEO Services
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="product__area pb-50">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12">
                <div className="product__wrapper">
                  <div className="postbox__details mb-30">
                    <p className="drop-cap">
                      Search Engine Optimization (SEO) is crucial for ensuring
                      your business's online visibility. At Odidor, we
                      specialize in optimizing your website to rank higher in
                      search results, driving targeted traffic, and improving
                      your digital presence. Our goal is to help your business
                      grow organically and sustainably through search engines
                      like Google, Bing, and more.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 product__details-thumb w-img mb-30">
                <img
                  src="/../assets/img/services/digitalmarketing/SEO.gif"
                  alt="SEO Strategy"
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 postbox__details">
                <p>
                  Our SEO services cover a broad spectrum, including keyword
                  research, on-page optimization, content development, and
                  technical SEO enhancements. We use the latest SEO techniques
                  and tools to ensure your website not only ranks higher but
                  also remains compliant with the latest search engine
                  algorithms.
                  <br />
                  <br />
                  We tailor our strategies to meet your specific business goals,
                  ensuring your website achieves better visibility across all
                  search engines. From improving page load times to enhancing
                  mobile usability and structuring data correctly, we cover
                  every aspect of technical SEO to help your site perform at its
                  best.
                  <br />
                  <br />
                  We also offer robust link-building services and local SEO,
                  helping businesses rank higher for location-specific queries.
                  Our local SEO strategies are designed to boost your business
                  in geographically targeted searches, making it easier for
                  customers to find you in their area.
                  <br />
                  <br />
                  Additionally, we provide ongoing SEO audits, competitive
                  analysis, and monthly reports to track your website's
                  performance. Our comprehensive approach ensures your website's
                  long-term success and dominance in search engine rankings.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="services__area pb-110">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="section__title-wrapper mb-50 text-center">
                  <h2 className="section__title">Our SEO Expertise</h2>
                  <p>
                    Tailored SEO services designed to boost your website's
                    visibility and rank higher on search engines.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              {/* Existing Technology Cards */}
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 d-flex">
                <div
                  className="services__item white-bg mb-30 wow fadeinup animated"
                  data-wow-delay=".9s"
                >
                  <div className="services__icon mb-45">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-4">
                        <span className="blue-bg-4">
                          <i className="fas fa-search fa-4x" />
                        </span>
                      </div>
                      <div className="col-8">
                        <h3 className="services__title">
                          <a href="/services/seo/keyword-research">
                            Keyword Research
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="services__content">
                    <ul>
                      <li>
                        &#8226; Identifying the most relevant keywords for your
                        business.
                      </li>
                      <li>
                        &#8226; Boosting your visibility on search engines.
                      </li>
                      <li>
                        &#8226; Targeting the right audience with tailored
                        keywords.
                      </li>
                    </ul>
                    <a href="/contact" className="link-btn">
                      <i className="arrow_right"></i>Learn More
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 d-flex">
                <div
                  className="services__item white-bg mb-30 wow fadeinup animated"
                  data-wow-delay="1.1s"
                >
                  <div className="services__icon mb-45">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-4">
                        <span className="blue-bg-4">
                          <i className="fas fa-link fa-4x" />
                        </span>
                      </div>
                      <div className="col-8">
                        <h3 className="services__title">
                          <a href="/services/seo/link-building">
                            Link Building
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="services__content">
                    <ul>
                      <li>
                        &#8226; High-quality backlinks for improved domain
                        authority.
                      </li>
                      <li>
                        &#8226; Ethical link-building practices for sustainable
                        growth.
                      </li>
                    </ul>
                    <a href="/contact" className="link-btn">
                      <i className="arrow_right"></i>Learn More
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 d-flex">
                <div
                  className="services__item white-bg mb-30 wow fadeinup animated"
                  data-wow-delay="1.3s"
                >
                  <div className="services__icon mb-45">
                    <div className="row justify-content-center align-items-center">
                      <div className="col-4">
                        <span className="blue-bg-4">
                          <i className="fas fa-map-marker-alt fa-4x" />
                        </span>
                      </div>
                      <div className="col-8">
                        <h3 className="services__title">
                          <a href="/services/seo/local-seo">Local SEO</a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="services__content">
                    <ul>
                      <li>
                        &#8226; Optimizing your website for local search
                        queries.
                      </li>
                      <li>
                        &#8226; Improving visibility in location-specific
                        results.
                      </li>
                      <li>
                        &#8226; Attracting more local customers to your
                        business.
                      </li>
                    </ul>
                    <a href="/contact" className="link-btn">
                      <i className="arrow_right"></i>Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-12">
                <div className="section__title-wrapper mt-50 mb-50">
                  <h4 className="h3">Key Factors for SEO Success:</h4>
                  <p>
                    SEO is a continuous process. Essential elements include
                    high-quality content, strong backlinks, and technical
                    optimizations to ensure your website stays ahead in search
                    rankings.
                  </p>
                  <ul>
                    <li>
                      &#8226; <b>Content Quality:</b> Creating relevant,
                      valuable content that resonates with your target audience.
                    </li>
                    <li>
                      &#8226; <b>Backlinks:</b> Building a solid backlink
                      profile to boost credibility and authority.
                    </li>
                    <li>
                      &#8226; <b>Technical SEO:</b> Optimizing your website’s
                      structure and performance for search engines.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
};

export default Seoservices;
