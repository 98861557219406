import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Header />
            <div class="bg-shape">
                <img src="../assets/img/shape/shape-1.png" alt="Odidor  app development" />
            </div>

            <section class="page__title-area  pt-50">
                <div class="container">
                    <div class="row">
                        <div class="col-xxl-12">
                            <div class="page__title-content mb-50">
                                <h1 class="page__title">Company Privacy Policy</h1>
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li class="breadcrumb-item"><Link to="/services">Services</Link></li>
                                        <li class="breadcrumb-item active" aria-current="page"> Privacy Policy</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="container pb-60">
                <div class="row">
                    <div class="col-12">
                        <h1 class="pagetitle__heading mb-10">Policy</h1>


                        <h1>Privacy Policy</h1>
                        <p>Last updated: July 15, 2021</p>
                        <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
                            information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
                        <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the
                            collection and use of information in accordance with this Privacy Policy.</p>
                        <h1>Interpretation and Definitions</h1>
                        <h2>Interpretation</h2>
                        <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.
                            The following definitions shall have the same meaning regardless of whether they appear in singular or in
                            plural.</p>
                        <h2>Definitions</h2>
                        <p>For the purposes of this Privacy Policy:</p>
                        <ul>
                            <li>
                                <p><strong>Account</strong> means a unique account created for You to access our Service or parts of our
                                    Service.</p>
                            </li>
                            <li>
                                <p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot;
                                    or &quot;Our&quot; in this Agreement) refers to Odidor .</p>
                            </li>
                            <li>
                                <p><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other
                                    device by a website, containing the details of Your browsing history on that website among its many uses.
                                </p>
                            </li>
                            <li>
                                <p><strong>Country</strong> refers to: British Columbia, Canada</p>
                            </li>
                            <li>
                                <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a
                                    digital tablet.</p>
                            </li>
                            <li>
                                <p><strong>Personal Data</strong> is any information that relates to an identified or identifiable
                                    individual.</p>
                            </li>
                            <li>
                                <p><strong>Service</strong> refers to the Website.</p>
                            </li>
                            <li>
                                <p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of
                                    the Company. It refers to third-party companies or individuals employed by the Company to facilitate the
                                    Service, to provide the Service on behalf of the Company, to perform services related to the Service or to
                                    assist the Company in analyzing how the Service is used.</p>
                            </li>
                            <li>
                                <p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the
                                    Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
                            </li>
                            <li>
                                <p><strong>Website</strong> refers to Do It, accessible from <a href="https://odidor.co/"
                                    rel="external nofollow noopener" target="_blank">https://odidor.co/</a></p>
                            </li>
                            <li>
                                <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal
                                    entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
                            </li>
                        </ul>
                        <h1>Collecting and Using Your Personal Data</h1>
                        <h2>Types of Data Collected</h2>
                        <h3>Personal Data</h3>
                        <p>While using Our Service, we won't collect any kind of personal data. All app data is stored locally in
                            your(user) device</p>
                        <ul>

                        </ul>

                        <ul>
                            <li><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your Device. You can
                                instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do
                                not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your
                                browser setting so that it will refuse Cookies, our Service may use Cookies.</li>
                            <li><strong>Flash Cookies.</strong> Certain features of our Service may use local stored objects (or Flash
                                Cookies) to collect and store information about Your preferences or Your activity on our Service. Flash
                                Cookies are not managed by the same browser settings as those used for Browser Cookies. For more information
                                on how You can delete Flash Cookies, please read &quot;Where can I change the settings for disabling, or
                                deleting local shared objects?&quot; available at <a
                                    href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"
                                    rel="external nofollow noopener"
                                    target="_blank">https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_</a>
                            </li>
                            <li><strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small electronic
                                files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit
                                the Company, for example, to count users who have visited those pages or opened an email and for other
                                related website statistics (for example, recording the popularity of a certain section and verifying system
                                and server integrity).</li>
                        </ul>
                        <p>Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on Your
                            personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close
                            Your web browser. Learn more about cookies: <a href="https://www.freeprivacypolicy.com/blog/cookies/"
                                target="_blank">Cookies: What Do They Do?</a>.</p>
                        <p>We use are not using both Session and Persistent Cookies </p>


                        <h1>Contact Us</h1>
                        <p>If you have any questions about this Privacy Policy, You can contact us:</p>
                        <ul>
                            <li>By email: ryan@odidor.co</li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default PrivacyPolicy