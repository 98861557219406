import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../../components/Header'
import ServiceSMCard from '../../components/ServiceSMCard'
import ServiceSupport from '../../components/ServiceSupport'
import ServiceSidebar from '../../components/ServiceSidebar'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import ContactForm from '../../components/ContactForm'

const Surrey = () => {
    return (
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="format-detection" content="telephone=no" />
                <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
                <link rel="canonical" href="https://odidor.co/services/app-development" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />

                <title>Web Development & Design, Surrey BC</title>
                <meta name="description" content="If you want to create a stunning website that will mesmerize your audience, you need to choose the right web design company. Odidor is a reputable web design company that offers exceptional web design services in Surrey and Greater Vancouver area." />
                <meta name="keywords" content="Web design in surrey bc, web development in Surrey, webdesign agency near me, Ecommerce development, surrey webdesigner, digital marketing, ecommerce platform, web apps development, custom web devloping in surrey, surrey web services" />
                <meta property="og:title" content="Web Development & Design, Surrey BC" />
                <meta property="og:description"
                    content="If you want to create a stunning website that will mesmerize your audience, you need to choose the right web design company. Odidor is a reputable web design company that offers exceptional web design services in Surrey and Greater Vancouver area." />
                <meta property="og:type" content="Website" />
                <meta property="og:url" content="https://odidor.co/location/surrey" />
                <meta property="og:image" content="https://odidor.co/assets/img/locations/surrey.jpg" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />

                <meta name="twitter:title" content="Web Development & Design, Surrey BC" />
                <meta name="twitter:description"
                    content="If you want to create a stunning website that will mesmerize your audience, you need to choose the right web design company. Odidor is a reputable web design company that offers exceptional web design services in Surrey and Greater Vancouver area." />
                <meta name="twitter:image" content="https://odidor.co/assets/img/locations/surrey.jpg" />
                <meta name="twitter:card" content="Website" />

                <meta property="og:site_name" content="Odidor " />
                <meta property="og:title" content="Web Development & Design, Surrey BC" />
                <meta property="og:type" content="Website" />
                <meta property="og:description"
                    content="If you want to create a stunning website that will mesmerize your audience, you need to choose the right web design company. Odidor is a reputable web design company that offers exceptional web design services in Surrey and Greater Vancouver area." />
                <meta property="og:image" content="https://odidor.co/assets/img/locations/surrey.jpg" />
                <meta property="og:url" content="https://odidor.co/location/surrey" />
            </Helmet>
            <main>
                <Header />
                <div className="bg-shape">
                    <img src="../assets/img/shape/shape-1.png" alt="Odidor  app development" />
                </div>

                <section className="page__title-area  pt-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="page__title-content mb-50">
                                    <h1 className="h3">Web Development & Digital Marketing Services in Surrey</h1>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                            <li className="breadcrumb-item"><Link to="/">Locations</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">
                                                <Link to="/location/surrey">Surrey</Link>
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="product__area pb-115">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-8 col-xl-8 col-lg-8">
                                <div className="product__wrapper">

                                    <div className="postbox__details mb-30">
                                        <p className="drop-cap"><a href="https://www.surrey.ca/" target='_blank'>Surrey's</a> leading expert in web development and digital marketing. Our team of seasoned professionals is dedicated to crafting exceptional online experiences and implementing powerful digital strategies tailored to your unique business needs. Are you ready to elevate your online presence and drive unparalleled success for your business? At Odidor, we understand the significance of a robust online presence in today's competitive business landscape. Our web development services are designed to create visually stunning, user-friendly websites that captivate your audience and turn visitors into loyal customers. Whether you need a brand-new website or want to revamp your existing one, our team has the expertise to deliver outstanding results.
                                        </p>
                                    </div>
                                    <div className="product__details-thumb w-img mb-30">
                                        <img src="../assets/img/locations/surrey.jpg" alt="City of Surrey, BC" />
                                    </div>
                                    <div className="product__features mt-10">
                                        <h3 className="product__overview-title">Our Web Development Process:</h3>
                                        <ol>
                                            <li>
                                                <p><b>Consultation</b>: We begin by understanding your business goals, target audience, and unique selling points.</p>
                                            </li>
                                            <li>
                                                <p><b>Custom Design</b>: Our talented designers create a visually appealing and intuitive website tailored to your brand.</p>
                                            </li>
                                            <li>
                                                <p><b>Development</b>: Our skilled developers bring the design to life, ensuring a seamless and responsive user experience.</p>
                                            </li>
                                            <li>
                                                <p><b>Testing</b>: Rigorous testing ensures your website performs flawlessly across devices and browsers.</p>
                                            </li>
                                            <li>
                                                <p><b>Launch</b>: We deploy your website, ensuring a smooth transition from development to a live, operational platform.</p>
                                            </li>
                                        </ol>
                                    </div>


                                    <div className="postbox__details mb-30">
                                        <h3>Digital Marketing Excellence in Surrey</h3>
                                        <p>Unlock the full potential of your business with our comprehensive digital marketing services. Odidor employs cutting-edge strategies to boost your online visibility, increase traffic, and drive conversions.
                                        </p>
                                        <div className="product__features mt-10">
                                            <h3 className="product__overview-title">Our Digital Marketing Solutions:</h3>
                                            <ol>
                                                <li>
                                                    <p><b>Search Engine Optimization (SEO)</b>: Dominate search engine rankings and attract organic traffic with our proven SEO strategies.</p>
                                                </li>
                                                <li>
                                                    <p><b>Social Media Marketing</b>: Engage your audience on popular social platforms, build brand loyalty, and drive leads and sales.</p>
                                                </li>
                                                <li>
                                                    <p><b>Pay-Per-Click (PPC) Advertising</b>: Maximize your ROI with targeted and data-driven PPC campaigns.</p>
                                                </li>
                                                <li>
                                                    <p><b>Content Marketing</b>: Our compelling content strategies position your brand as an industry authority, attracting and retaining customers.</p>
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                    <div className="">
                                        <h3>Let's talk about the project</h3>
                                        <ContactForm />
                                        <br />
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d167148.98040221274!2d-122.96550177828962!3d49.1112858956434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485dc034d3fa75b%3A0xd28b4898abd598e0!2sSurrey%2C%20BC!5e0!3m2!1sen!2sca!4v1701584591849!5m2!1sen!2sca" width="100%" height="450" />
                                    </div>
                                </div>
                            </div>
                            <ServiceSidebar />
                        </div>
                    </div>
                </section>
                <Footer />
            </main>
        </>
    )
}

export default Surrey