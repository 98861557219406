import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../../components/Header'
import ServiceSMCard from '../../components/ServiceSMCard'
import ServiceSupport from '../../components/ServiceSupport'
import ServiceSidebar from '../../components/ServiceSidebar'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import ContactForm from '../../components/ContactForm'

const Vancouver = () => {
    return (
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="format-detection" content="telephone=no" />
                <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' />
                <link rel="canonical" href="https://odidor.co/services/app-development" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />

                <title>Web Development & Design, Vancouver</title>
                <meta name="description" content="Whether you’re a start-up or an established company in Vancouver, Odidor is your go-to partner for exceptional web development and digital marketing services." />
                <meta name="keywords" content="Web design in vancouver, web development in Vancouver, webdesign agency near me, Ecommerce dev vancouver, vancouver webdesigner, digital marketing vancouver, vancouver local web design, apps development agency, custom web devloping in Vancouver, Vancouver web services" />
                <meta property="og:title" content="Web Development & Design, Vancouver" />
                <meta property="og:description"
                    content="Whether you’re a start-up or an established company in Vancouver, Odidor is your go-to partner for exceptional web development and digital marketing services." />
                <meta property="og:type" content="Website" />
                <meta property="og:url" content="https://odidor.co/location/vancouver" />
                <meta property="og:image" content="https://odidor.co/assets/img/locations/vancouver.jpg" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />

                <meta name="twitter:title" content="Web Development & Design, Vancouver" />
                <meta name="twitter:description"
                    content="Whether you’re a start-up or an established company in Vancouver, Odidor is your go-to partner for exceptional web development and digital marketing services." />
                <meta name="twitter:image" content="https://odidor.co/assets/img/locations/vancouver.jpg" />
                <meta name="twitter:card" content="Website" />

                <meta property="og:site_name" content="Odidor " />
                <meta property="og:title" content="Web Development & Design, Vancouver" />
                <meta property="og:type" content="Website" />
                <meta property="og:description"
                    content="Whether you’re a start-up or an established company in Vancouver, Odidor is your go-to partner for exceptional web development and digital marketing services." />
                <meta property="og:image" content="https://odidor.co/assets/img/locations/vancouver.jpg" />
                <meta property="og:url" content="https://odidor.co/location/surrey" />
            </Helmet>
            <main>
                <Header />
                <div className="bg-shape">
                    <img src="../assets/img/shape/shape-1.png" alt="Odidor  app development" />
                </div>

                <section className="page__title-area  pt-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="page__title-content mb-50">
                                    <h1 className="h3">Your Premier Web Development and Digital Marketing Partner in Vancouver</h1>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                            <li className="breadcrumb-item"><Link to="/">Locations</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">
                                                <Link to="/location/vancouver">Vancouver</Link>
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="product__area pb-115">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-8 col-xl-8 col-lg-8">
                                <div className="product__wrapper">

                                    <div className="postbox__details mb-30">
                                        <p className="drop-cap"> In today’s digital age, having a robust online presence is essential for businesses of all sizes. Whether you’re a start-up or an established company in <a href="https://vancouver.ca/about-vancouver.aspx" target='_blank'>Vancouver</a>, Odidor is your go-to partner for exceptional web development and digital marketing services. Our mission is to help businesses thrive in the competitive online landscape through innovative solutions and customized strategies.
                                        </p>
                                    </div>
                                    <div className="product__details-thumb w-img mb-30">
                                        <img src="../assets/img/locations/vancouver.jpg" alt="City of Vancouver, BC" />
                                    </div>
                                    <div className="product__features mt-10">
                                        <h2>Why Choose Odidor?</h2><br />
                                        <h3 className="product__overview-title">1. Local Expertise and Global Reach</h3>
                                        <p>Odidor understands the unique needs of Vancouver businesses. Our local expertise ensures that we tailor our services to meet the specific demands of the Vancouver market while leveraging global best practices. This blend of local knowledge and global insights gives our clients a competitive edge.</p>
                                    </div>


                                    <div className="postbox__details mb-30">
                                        <h3>2. Comprehensive Web Development Services</h3>
                                        <p>At Odidor, we specialize in creating visually stunning, highly functional websites that are optimized for performance. Our web development services include:
                                        </p>
                                        <div className="product__features mt-10">
                                            <h5 className="">Our Development strategic:</h5>
                                            <ol>
                                                <li>
                                                    <p><b>Custom Website Design</b>: We create bespoke designs that reflect your brand identity and engage your target audience.</p>
                                                </li>
                                                <li>
                                                    <p><b>Responsive Development</b>: Our websites are mobile-friendly, ensuring a seamless experience across all devices.</p>
                                                </li>
                                                <li>
                                                    <p><b>Content Management Systems (CMS)</b>: We develop and integrate powerful CMS platforms like <a href='https://about.magento.com/Magento-Commerce' target='_blank'> Magento</a>, <a href='https://www.drupal.org/'>Drupal</a>, <a href='https://www.joomla.org/'>Joomla</a>, enabling you to manage your content effortlessly.</p>
                                                </li>
                                            </ol>
                                        </div>
                                    </div>

                                    <div className="postbox__details mb-30">
                                        <h3>3. Strategic Digital Marketing Solutions</h3>
                                        <p>Digital marketing is crucial for driving traffic, generating leads, and boosting conversions. Odidor offers a comprehensive suite of digital marketing services designed to elevate your brand’s online visibility:
                                        </p>
                                        <div className="product__features mt-10">
                                            <h5 className="">Our Marketing strategic:</h5>
                                            <ol>
                                                <li>
                                                    <p><b>Search Engine Optimization (SEO)</b>: Our SEO experts optimize your website to rank higher on search engines, making it easier for potential customers in Vancouver to find you.</p>
                                                </li>
                                                <li>
                                                    <p><b>Pay-Per-Click (PPC) Advertising</b>: We create targeted PPC campaigns that deliver measurable results and a high return on investment.</p>
                                                </li>
                                                <li>
                                                    <p><b>Social Media Marketing</b>: Our social media strategies enhance your brand’s presence on platforms like Facebook, Instagram, and LinkedIn.</p>
                                                </li>
                                                <li>
                                                    <p><b>Content Marketing</b>: We develop engaging, valuable content that resonates with your audience and establishes your authority in the industry.</p>
                                                </li>
                                                <li>
                                                    <p><b>Email Marketing</b>: Our personalized email campaigns nurture leads and drive customer loyalty.</p>
                                                </li>
                                            </ol>
                                        </div>
                                    </div>

                                    <div className="product__details-thumb w-img mb-30">
                                        <h2>Success Stories</h2>
                                        <p>Odidor has helped numerous Vancouver businesses achieve their digital goals. From small enterprises to large corporations, our clients have experienced significant growth and increased online visibility through our tailored web development and digital marketing strategies. We have listed below our happy clients.</p><br />

                                        <h3>DBS Home Decor</h3>
                                        <img src="../assets/img/locations/clients/dbs.jpg" alt="DBS Blinds Website Picture" />
                                        <p className='mt-2'><a className="text-primary" href='https://www.dbsblinds.ca/' title='Happy client of Odidor'><b>DBS Home Decor</b></a> a window blinds manufacturer approached us to revamp their website and boost their online presence. Our team developed a modern, user-friendly website with integrated e-commerce capabilities. Additionally, our <a className="text-primary" href='/services/digital-marketing' title='SEO Page'>targeted SEO</a> and social media campaigns resulted in a 50% increase in website traffic and a 30% boost in online sales within the first three months.</p>
                                        <br />

                                        <h3>Island Barbers</h3>
                                        <img src="../assets/img/locations/clients/island-barbers.jpg" alt="Island Barbers Website" />
                                        <p className='mt-2'><a className="text-primary" href='https://islandbarbers.ca/' title='Happy client of Odidor'><b>Island Barbers</b></a>, a beloved local barber shop, teamed up to boost sales within the community and elevate their brand presence. Our collaborative efforts between the development and design teams resulted in the creation of a visually stunning and user-friendly website for Island Barbers, built on the React JS platform.</p>
                                        <br />

                                    </div>

                                    {/* City Map */}
                                    <div className="">
                                        <h2> Partner with Odidor Today</h2>
                                        <p>
                                            Are you ready to transform your online presence and achieve your business goals? Partner with Odidor, Vancouver’s leading web development and digital marketing agency. Contact us today to schedule a consultation and discover how we can help your business succeed in the digital world.</p>
                                        <h3>Get a Quote today!!</h3>
                                        <ContactForm />
                                        <br />
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d83327.511174602!2d-123.123904!3d49.25773545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548673f143a94fb3%3A0xbb9196ea9b81f38b!2sVancouver%2C%20BC!5e0!3m2!1sen!2sca!4v1717191282293!5m2!1sen!2sca" width="100%" height="450" />
                                    </div>
                                </div>
                            </div>
                            <ServiceSidebar />
                        </div>
                    </div>
                </section>
                <Footer />
            </main>
        </>
    )
}

export default Vancouver