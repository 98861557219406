
import React, { useRef } from 'react'
import emailjs from 'emailjs-com'
import { useNavigate } from 'react-router-dom'

const ContactForm = () => {
    const navigate = useNavigate()

    //Email JS
    const form = useRef();

    const submitHandler = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_6vi7yu9', 'template_hcu45pp', form.current, 'UKLJOJp3C_GQ_cugL')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
        navigate("/Thank-You")
    };
    return (

        <div className="row">
            <div className="col-xxl-12 col-xl-12">
                <div className="contact__wrapper  white-bg">
                    <div className="contact__form">
                        <form ref={form} onSubmit={submitHandler}>
                            <div className="row">
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                                    <div className="contact__input-wrapper mb-25">
                                        <h5> Name</h5>
                                        <div className="contact__input">
                                            <input type="text" placeholder="Name" name="name" />
                                            <i className="fal fa-user"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                                    <div className="contact__input-wrapper mb-25">
                                        <h5>Company Name</h5>
                                        <div className="contact__input">
                                            <input type="email" placeholder="Email " name="email" />
                                            <i className="fal fa-envelope"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xxl-12">
                                    <div className="contact__input-wrapper mb-25">
                                        <h5>Message</h5>
                                        <div className="contact__input textarea">
                                            <textarea placeholder="Tell us a bil about your project" name="message"></textarea>
                                            <i className="fal fa-comment"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xxl-12">
                                    <button type="submit" className="m-btn m-btn-4"> <span></span> submit </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ContactForm